<template>
    <div class="commentRadio">
        <div class="group">
            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'supportive'" :id="customIds[0]"
                   class="supportive"/>
            <label :for="customIds[0]" class="radioLabel">
                <small>Supportive</small>
            </label>
            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'neutral'" :id="customIds[1]"
                   class="neutral"/>
            <label :for="customIds[1]" class="radioLabel">
                <small>Neutral</small>
            </label>
            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'critique'" :id="customIds[2]"
                   class="critique"/>
            <label :for="customIds[2]" class="radioLabel">
                <small>Critique</small>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RadioButton",
        props: {
            commentTag: String,
            isModal: Boolean,
            customIds:Array
        },
        data() {
            return {
                commentTagSelected: ""
            }
        },
        mounted() {
            this.commentTagSelected = this.commentTag
        },
        watch: {
            commentTag() {
                this.commentTagSelected = this.commentTag
            },
            commentTagSelected(val) {
                if (!val) return
                if (this.isModal === true) {
                    this.$emit('emitCommentTagModal', val)
                }
                else {
                    this.$emit('emitCommentTag', val)
                }
            }
        }
    }
</script>

<style scooped>
    .commentRadio .group {
        display: flex;
        align-items: center;
        margin-bottom:10px;
    }


    .commentRadio input[type=radio] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .commentRadio label {
        position: relative;
        margin-right: 1em;
        padding-left: 14px;
        padding-right: 1em;
        line-height: 2;
        cursor: pointer;
        color: #6c757d;
        font-size: 10px;
    }

    .commentRadio label:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        width: 10px;
        height: 11px;
        border: 2px solid #17a2b8;
        border-radius: 18px;

    }

    .commentRadio input[type=radio] + label::before {
        border-radius: 1em;
    }

    /* Checked */

    .commentRadio input[type=radio]:checked + label {
        padding-left: 1em;
        color: #fff;
    }

    .commentRadio .supportive:checked + label::before {
        top: 0;
        width: 100%;
        height: 2em;
        background: #28a745;
        border: 1px solid #28a745;
        z-index: auto;
    }

    .commentRadio .neutral:checked + label::before {
        top: 0;
        width: 100%;
        height: 2em;
        background: black;
        border: 1px solid black;
        z-index: auto;
    }

    .commentRadio .critique:checked + label::before {
        top: 0;
        width: 100%;
        height: 2em;
        background: #dc3545;
        border: 1px solid #dc3545;
        z-index: auto;
    }

    .commentRadio label,
    .commentRadio label::before {
        transition: 0.25s all ease;
    }

    .commentRadio small {
        position: relative;
        z-index: 1;
        font-size: 10px;
    }
</style>