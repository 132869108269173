export default {
    articles: [],
    breaking: [],
    upcoming: [],
    subscribed: [],
    searchArticles:[],
    searchUsers:[],
    draftAndUpcoming: [],
    draftPage: 1,
    publishPage: 1,
    breakingPage: 1,
    upcomingPage: 1,
    getHashtagPage:1,
    publishedArticles: [],
    allBreakingArticles: [],
    allUpcomingArticles:[],
    allHashtagArticles:[],
    articleDetail: {
        id:''
    },
    allCommentsSendPage:1,
    allCommentsSend:[],
    allCommentsReceivedPage:1,
    allCommentsReceived:[],
    writerReplyCommentsSendPage:1,
    writerReplyCommentsSend:[],
    writerReplyCommentsReceivedPage:1,
    writerReplyCommentsReceived:[],
    articleSearch:[],
    articleDetailCommentsPageNumber:1,
    articleDetailComments:[],
    hashTagData : localStorage.getItem('hashTagData') ? JSON.parse(localStorage.getItem('hashTagData')) : {slug:"",hash_tag_name:""},
    categoryArticle: {
        page:1,
        articles: [],
        breaking: [],
        upcoming: [],
        subscribed: []
    },
    paginate: {
        page: 1,
        links: {
            first: null,
            last: null,
            prev: null,
            next: null
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: 16,
            to: 1,
            total: 1,
            custom: null
        },
    }
}
