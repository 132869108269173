<template>
    <div id="articlePage" class="mt-4">
        <div class="">
            <ArticleNavbar :navBarData="navBarArray"/>
        </div>
    </div>
</template>

<script>
    import ArticleNavbar from "../components/Navbars/ArticleNavbar";

    export default {
        name: "writeArticle",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=1200 , initial-scale=0.4' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Write Article'
        },
        components: {
            ArticleNavbar,
        },
        data() {
            return {
                 navBarArray: [{id: 1, title: "write article"}, {id: 2, title: "heading Requests"}],
            };
        },
    };
</script>


