<template>
    <div id="login_modal">

        <div class="customize_model">


            <!-- Modal -->

            <div class="modal fade" data-backdrop="static" data-keyboard="false" id="LoginModal" tabindex="-1"
                 role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg  modal-dialog-centered dialog-login" role="document">
                    <div class="modal-content">

                        <!---header ----->
                        <div class=" p-2">
                            <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!---modal-body---->
                        <div class="modal-body">

                            <div class="body_text">
                                <!---logo--->
                                <div class="logo mb-5 mb-sm-3 mb-lg-5 mb-md-5 mb-xl-5">
                                    <img src="../../assets/images/worded-logo.png" class="d-block w-50 mx-auto">
                                </div>
                                <!-----Google+----->
                                <div class="login-details col-lg-8  col-md-8 col-sm-12 mx-auto">
                                    <div class="entry  mx-auto pt-1 pb-1 mb-4">
                                        <GoogleLogin class="d-block mx-auto justify-content-center btn-sm link-btn" :params="params"
                                                     :onSuccess="onGmailSuccess" :onFailure="onFailure">
                                            <svg width="25" height="25" class="nl hd af google-svg">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                                                          fill="#4285F4"></path>
                                                    <path d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                                                          fill="#34A853"></path>
                                                    <path d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                                                          fill="#FBBC05"></path>
                                                    <path d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                                                          fill="#EA4335"></path>
                                                </g>
                                            </svg>
                                            Continue with Google
                                        </GoogleLogin>
                                    </div>
                                    <!--                                    <div class="entry  mx-auto pt-1 pb-1 mb-4">-->
                                    <!--                                        <a class=" btn-sm justify-content-center link-btn" href="" title="Google+">-->
                                    <!--                                            <svg width="25" height="25" class="nl hd af">-->
                                    <!--                                                <g fill="none" fill-rule="evenodd">-->
                                    <!--                                                    <path d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"-->
                                    <!--                                                          fill="#4285F4"></path>-->
                                    <!--                                                    <path d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"-->
                                    <!--                                                          fill="#34A853"></path>-->
                                    <!--                                                    <path d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"-->
                                    <!--                                                          fill="#FBBC05"></path>-->
                                    <!--                                                    <path d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"-->
                                    <!--                                                          fill="#EA4335"></path>-->
                                    <!--                                                </g>-->
                                    <!--                                            </svg>-->
                                    <!--                                            Continue with Google-->
                                    <!--</a>-->
                                    <!--</div>-->


                                    <div class="entry faceBook-login-btn mx-auto pt-1 pb-1 mb-4">
                                        <facebook-login class="button" loginLabel="Continue with Facebook"
                                                        @sdk-loaded="sdkLoaded" app-id="563608441810538"
                                                        @login="getUserData" @logout="onLogout">
                                        </facebook-login>
                                        <!--                                        <svg width="25" height="25" fill="#3B5998" class="nl hd af">-->
                                        <!--                                            <path d="M20.3 4H4.7a.7.7 0 0 0-.7.7v15.6c0 .38.32.7.7.7h8.33v-6.38h-2.12v-2.65h2.12V9.84c0-2.2 1.4-3.27 3.35-3.27.94 0 1.75.07 1.98.1v2.3H17c-1.06 0-1.31.5-1.31 1.24v1.76h2.65l-.53 2.65H15.7l.04 6.38h4.56a.7.7 0 0 0 .71-.7V4.7a.7.7 0 0 0-.7-.7"-->
                                        <!--                                                  fill-rule="evenodd"></path>-->
                                        <!--                                        </svg>-->
                                        <!--                                        Continue with Facebook-->
                                    </div>
                                </div>
                            </div>
                            <!---small footer---->
                            <div id="footer" class=" mx-auto  mt-4  mb-2">
                                <span> By clicking on <b class="continue-with">"Continue with"</b> Google/Facebook, you agree to our
                                    <a href="https://theworded.com/privacypolicy" class="footer-text"><b>Privacy Policy</b></a>  and
                                    <a href="https://theworded.com/termsofservices" class="footer-text"><b>Terms of Service</b></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GoogleLogin from 'vue-google-login';
    import facebookLogin from 'facebook-login-vuejs';
    import $ from "jquery";

    export default {
        components: {
            GoogleLogin,
            facebookLogin
        },
        data() {
            return {
                params: {
                    client_id: "1062773302676-9jeoib8jsjnugtlcp65h457epkbhhvt0.apps.googleusercontent.com",
                },
                FB: undefined,
                buttonloader: false,
                isConnected: false,
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
            }
        },
        methods: {
            onGmailSuccess(data) {
                console.log("data", data.getBasicProfile());
                let payload = {
                    email: data.getBasicProfile().getEmail(),
                    name: data.getBasicProfile().getName(),
                    avatar: data.getBasicProfile().getImageUrl(),
                    token: data.getBasicProfile().getId(),
                    social_id: data.getBasicProfile().getId(),
                    social_type: 'google',
                }
                this.$store.dispatch('auth/login', payload)
                this.closeModal('LoginModal')
                this.$emit('emitCloseModal', false)
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                let removeClass = $(".modal-backdrop");
                removeClass.remove();
                $('body').removeClass('modal-open');
            },
            sdkLoaded(payload) {
                this.isConnected = payload.isConnected
                this.FB = payload.FB
                console.log("payload", payload)
                if (this.isConnected) this.getUserData()
            },
            onLogout() {
                console.log("this.isConnected = false;")
                this.isConnected = false;
            },
            getUserData(data) {
                let token = data.response.authResponse.accessToken;
                this.$store.dispatch('auth/facebookLogin', token).then(response => {
                    console.log(response);
                        let payload = {
                            token: response.data.id,
                            social_id: response.data.id,
                            social_type: 'facebook',
                            avatar: '',
                            email: (response.data.email) ? response.data.email : "",
                            name: (response.data.name) ? response.data.name : "",
                        }
                        this.$store.dispatch('auth/login', payload).then(() => {
                            this.closeModal('LoginModal')
                            this.$emit('emitCloseModal', false)
                        })
                    });
            },
            onFailure(data) {
                console.log("==Error=>", data)
            },
        }
    }
</script>

<style scoped>
 .entry {
        border-radius: 30px;
        padding-top: 7px;
        border: 1px solid grey;
    }
    .entry a {
        height: 28px;
        letter-spacing: inherit;
        padding: 0 37px;
        text-decoration: none;
    }
    .modal-dialog {
        max-width: 700px;
        width: 100%;
    }
    .modal-body {
        padding: 1rem;
    }
   .faceBook-login-btn  .button button{
background-color: transparent!important;
   }
    .body_text {
        width: 65%;
        margin: auto;
    }
    .link-btn {
        border: none;
        background-color: white;
        border-radius: 50%;
        width: 100%;
    }
   
    .button button {
          border-radius: 20px !important;
    }

    #footer {
        width: 63%;
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
    }
    #footer .footer-text{
  color: grey;
    }
    #footer .continue-with{
    color:black!important;
    }
   
</style>