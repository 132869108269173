<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" id="publishModal" data-backdrop="static" data-keyboard="false"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg popup">
                <div class="modal-content">
                    <div class="modal-header justify-content-center position-relative">
                        <h5 class="modal-title" id="staticBackdropLabel">Select Categories</h5>
                        <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3 px-5">
                        <div class="category-tags-wrapper">
                            <div class="category_tag w-25">
                                <label class="ml-4  title mb-none">Category Tags</label>
                            </div>
                            <CountrySelect :isCustomHomePage="false" @updateCountyId="updateCountyId"/>
                        </div>
                        <hr class="line"/>
                        <div class="row pt-2 mb-3">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="form-label fst-italic">
                                        <img src="https://theworded.com/testing/www/html/public/images/flags/globe.png"
                                             class="icon ml-2" alt="icon" width="20px">
                                        tages</label>
                                    <MultiSelect v-model="selectWorldCategories"
                                                 deselectLabel=""
                                                 selectLabel=""
                                                 placeholder="Search Category Tags" label="name" track-by="code"
                                                 :options="worldCategories" :multiple="true" :taggable="true"
                                                 @tag="addWorldCategories"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <label class="form-label fst-italic">
                                    <img v-if="selectedFlag.id!==1" :src="selectedFlag.flag" class="icon ml-2"
                                         alt="icon" width="20px">
                                    tages</label>
                                <MultiSelect v-model="selectCategories"
                                             deselectLabel=""
                                             selectLabel=""
                                             :disabled="isDisabled"
                                             placeholder="Search Category Tags" label="name" track-by="code"
                                             :options="countryCategories" :multiple="true" :taggable="true"
                                             @tag="addCategories"/>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-12">
                                <div class="category_tag w-25">
                                    <label class="ml-4  title mb-none">Hashtags</label>
                                </div>
                                <hr class="line"/>
                            </div>
                        </div>
                        <div class="row pt-2 mb-3">
                            <div class="col-lg-6 ">
                                <div class="form-group">
                                    <label class="form-label fst-italic">
                                        <img src="https://theworded.com/testing/www/html/public/images/flags/globe.png"
                                             class="icon ml-2" alt="icon" width="20px">
                                        tages</label>
                                    <MultiSelect v-model="selectWorldHashTag"
                                                 deselectLabel=""
                                                 selectLabel=""
                                                 placeholder="Search Story Tags" label="name" track-by="code"
                                                 :options="worldHashTags" :multiple="true" :taggable="true"
                                                 @tag="addWorldHashTags"/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label class="form-label fst-italic">
                                    <img v-if="selectedFlag.id!==1" :src="selectedFlag.flag"
                                         class="icon ml-2" alt="icon" width="20px">
                                    tages</label>
                                <MultiSelect v-model="selectHashTag"
                                             deselectLabel=""
                                             selectLabel=""
                                             :disabled="isDisabled"
                                             placeholder="Search Story Tags" label="name" track-by="code"
                                             :options="countryHashTags" :multiple="true" :taggable="true"
                                             @tag="addHashTag"/>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer ">
                        <button type="button" @click="emitSubmitPublishModal" class="btn btn-success"
                                id="publish_button">{{isUpdate ? 'Update': 'Publish' }}
                        </button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import MultiSelect from 'vue-multiselect'
    import CountrySelect from '../CountrySelect/CountrySelect';
    import {mapGetters} from 'vuex';
    import $ from 'jquery';
    import 'bootstrap'
    import '@popperjs/core';


    export default {
        name: 'PublishModal',
        components: {
            MultiSelect,
            CountrySelect
        },
        props: {
            isSubmit: Boolean,
            isUpdate: Boolean,
            type: String,
        },
        data() {
            return {
                selectWorldCategories: [],
                selectWorldHashTag: [],
                selectCategories: [],
                selectHashTag: [],
                isDisabled: true,
            }
        },
        computed: {
            ...mapGetters({
                worldCategories: "category/getWorldCategories",
                worldHashTags: "category/getWorldHashTags",
                countryCategories: "category/getCountryCategories",
                countryHashTags: "category/getCountryHashTags",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",

            }),

        },
        methods: {
            addWorldCategories(newTag) {
                this.selectWorldCategories.push({name: newTag, code: -1})
            },
            getWorldCategories() {
                this.$store.dispatch('category/getWorldCategories', 1)
            },
            addWorldHashTags(newTag) {
                this.selectWorldHashTag.push({name: newTag, code: -1})
            },
            getWorldHashTags() {
                this.$store.dispatch('category/getWorldHashTags', 1)
            },
            addHashTag(newTag) {
                this.selectHashTag.push({name: newTag, code: -1})
            },
            addCategories(newTag) {
                this.selectCategories.push({name: newTag, code: -1})
            },
            updateCountyId(id) {
                this.isDisabled = id === 1;
                if(id === 1){
                    this.selectCategories = []
                    this.selectHashTag = []
                }

                this.getWorldCategories()
                this.getWorldHashTags()
                this.getCountryCategories(id)
                this.getCountryHashTags(id)
            },
            emitSubmitPublishModal: function () {
                if (this.$country.id === 1) {
                    if (this.selectWorldCategories.length === 0) {
                        console.log('please select world category')
                        return
                    }
                    if (this.selectWorldHashTag.length === 0) {
                        console.log('please select world hash tag')
                        return;
                    }
                }

                this.$emit("emitSubmitPublishModal", true)
                this.$emit('emitSelectWorldCategories', this.selectWorldCategories)
                this.$emit('emitSelectWorldHashTag', this.selectWorldHashTag)
                this.$emit('emitSelectCategories', this.selectCategories)
                this.$emit('emitSelectHashTag', this.selectHashTag)
                this.closeModal()
            },
            getCountryCategories(id) {
                this.$store.dispatch('category/getCountryCategories', id)
            },
            getCountryHashTags(id) {
                this.$store.dispatch('category/getCountryHashTags', id)

            },
            checkCountry() {
                this.isDisabled = this.defaultCountry.id === 1
            },
            closeModal() {
                $('#staticBackdrop').modal('hide');
                let removeClass = $(".modal-backdrop");
                removeClass.removeClass('show');
                removeClass.removeClass('fade')
                removeClass.removeClass('modal-backdrop')
            },

            edit() {
                let cardData = localStorage.getItem('cardData');
                if (cardData) {
                    let data = JSON.parse(cardData);
                    this.selectWorldCategories = [];
                    this.selectWorldHashTag = [];
                    this.selectCategories = [];
                    this.selectHashTag = [];
                    data.article_category.map(x => {
                        if (x.category.country_id === 1) {
                            this.selectWorldCategories.push({code: x.category.id, name: x.category.category_name})
                        }
                        if (x.hashtag.country_id === 1) {
                            this.selectWorldHashTag.push({code: x.hashtag.id, name: x.hashtag.hash_tag_name})
                        }
                        if (x.category.country_id > 1) {
                            this.selectCategories.push({code: x.category.id, name: x.category.category_name})
                        }
                        if (x.hashtag.country_id > 1) {
                            this.selectHashTag.push({code: x.hashtag.id, name: x.hashtag.hash_tag_name})
                        }

                    })
                }
            }

        },

        mounted() {
            this.edit();
            this.getWorldCategories()
            this.getWorldHashTags()
            if(this.defaultCountry){
                this.getCountryCategories(this.defaultCountry.id)
                this.getCountryHashTags(this.defaultCountry.id)
            }
            this.checkCountry()
        },
        watch: {
            isUpdate(val) {
                this.isUpdate = val
            }
        }
    }
</script>

<style scoped>
    #publish_button {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
        padding: 0 14px;
        margin-right: 2px;
        border-radius: 5px;
    }

    .title {
        font-size: 15px;
    }

    .popup {
        width: 700px;

    }

    .line {
        width: 100%;
        margin-block: 0;
    }

    .category-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
    }

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>