<template>
    <div>
<!--        <SubHeading category="Published" link="#"/>-->
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12  mx-auto order-xxl-first order-lg-first order-sm-last  order-last">
                    <div v-for="cardData in getPublishedArticles" :key="cardData.id+'_'+Math.random()">
                        <BlogCard :cardData="cardData" :isEdit="true"/>
                    </div>
            </div>
        </div>
        <infinite-loading
                spinner="spiral"
                @infinite="getPublishedArticle">
        </infinite-loading>
    </div>
</template>

<script>
    // import Card from "../components/Cards/Card";
    import BlogCard from "../components/BlogCard/BlogCard";
    // import SubHeading from "../components/Headings/SubHeading";
    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex';

    export default {
        name: "published",
        components: {
            // Card,
            BlogCard,
            // SubHeading,
            InfiniteLoading,
        },
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                getPublishedArticles: 'article/getPublishedArticles',
                getPublishedPage: 'article/getPublishedPage'
            })
        },
        methods: {
            getPublishedArticle($state) {
                let payload = {
                    page: this.getPublishedPage,
                    $state: $state
                }
                this.$store.dispatch('article/getPublishedArticles', payload)
            }
        },
        mounted() {
            this.getPublishedArticle()
        }
    };
</script>

