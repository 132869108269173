<template>
    <div class="blog-category">
        <div class="row">
            <div class="col-12 col-sm-12 first-top-category">
                <BlogHeading headingClass="category" category="Categories"/>
            </div>
            <div class="wraper col-12 col-sm-12 pt-3 category-blog slider-category">
                <div id="category" class="home-page-category">
                    <VueSlickCarousel v-bind="getSettings" :arrows="true" v-if="getCategories.length>0">
                        <div class="category-slide-inner" v-for="categories in getCategories"
                             :key="categories.id+'_'+Math.random()">
                            <button v-for="category in categories" :key="category.id+'_'+Math.random()"
                                    @click="activeClass(category)"
                                    :class="activeCategory.id === category.id? 'category-btn active':'category-btn' ">
                                {{category.category_name}}
                            </button>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>

            <div class="col-12 col-sm-12  d-xxl-block d-lg-block d-sm-none d-none mt-lg-4">
                <BlogHeading headingClass="trending" category="Trending"/>
                <div id="trending bg-info" class="trending-categories">
                    <VueSlickCarousel :arrows="true" v-bind="settingTrending" v-if="trendingHashTags.length>0">
                        <div v-for="(trendingData) in trendingHashTags" :key="trendingData.id+'_'+Math.random()">
                            <Trending v-for="trending in trendingData" :key="trending.id+'_'+Math.random()"
                                      :trending="trending" :type="type"/>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BlogHeading from "../BlogHeading/BlogHeading";
    import Trending from "../Trending/Trending";
    // import Pagination from 'vue-pagination-2'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


    import {mapGetters} from "vuex";

    export default {
        name: "BlogCategory",
        components: {
            BlogHeading,
            Trending,
            // Pagination,
            VueSlickCarousel
        },
        props: {
            type: String
        },
        data() {
            return {
                page: 1,
                perPage: 3,
                options: {
                    edgeNavigation: false
                },
                updatedCategories: [],
                settingTrending: {
                    "dots": false,
                    "dotsClass": "slick-dots custom-dot-class",
                    "edgeFriction": 0.35,
                    "infinite": false,
                    "speed": 500,
                    "slidesToShow": 1,
                    "slidesToScroll": 1
                }
            }
        },
        computed: {
            ...mapGetters({
                getCategories: "category/getCategories",
                activeCategory: "category/getActiveCategory",
                trendingHashTags: "category/getTrendingHashTags",
                getSettings: "category/getSettings",
            }),
        },
        methods: {
            activeClass(category) {
                this.$store.dispatch("category/updateActiveCategory", category).then(() => {
                    this.$emit('emitActiveCategory', category);
                });
            },
            callback(page) {
                this.updatedCategories = []
                this.updatedCategories.push(...this.paginate(this.getCategories, page))
            },
            paginate(array, page_number) {
                return array.slice((page_number - 1) * this.perPage, page_number * this.perPage)
            },
        },
        mounted() {
            // if(this.getCategories.length>0){
            //     console.log("update");
            //     this.updatedCategories.push(...this.paginate(this.getCategories, 1))
            // }

        }

    }
</script>

<style>
    .wraper {
        padding-bottom: 24px;
    }

</style>