<template>
    <div>
        <div class="row mt-4">
            <div class="main-home col-lg-8 col-md-12 col-sm-12 order-lg-first order-md-last">
                <SubHeading category="All Breaking Articles" link=""/>
                <div v-for="cardData in getAllBreakingArticles" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :cardData="cardData"/>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getAllPublishedForBreaking"
                ></infinite-loading>
            </div>
            <div :style="' left:'+mainHome+'px'" class="menu-home col-lg-4 col-m-12 col-sm-12 order-lg-last order-md-first">
                <CountrySelect :isCustom="getType" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                <br>
                <BlogCategory @emitActiveCategory="emitActiveCategory"/>
            </div>
        </div>

    </div>
</template>

<script>
    // import Card from "../components/Cards/Card";
    import BlogCard from "../components/BlogCard/BlogCard";
    import SubHeading from "../components/Headings/SubHeading";
    import BlogCategory from '../components/BlogCategory/BlogCategory'
    import CountrySelect from '../components/CountrySelect/CountrySelect'

    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex';
    import $ from "jquery";

    export default {
        name: "published",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Breaking Article'
        },
        components: {
            BlogCard,
            SubHeading,
            InfiniteLoading,
            BlogCategory,
            CountrySelect
        },
        data() {
            return {
                // type: "home",
                windowWidth: 0,
                mainHome: 0,
                // category: {
                //     "id": 0,
                //     "user_id": 0,
                //     "country_id": 0,
                //     "category_name": "home",
                //     "level": 0
                // },
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                getAllBreakingArticles: 'article/getAllBreakingArticles',
                getAllBreakingPage: 'article/getAllBreakingPage',
                activeCategory: "category/getActiveCategory",
                getType: "category/getType"
            })
        },
        methods: {
            getTrendingHashtag() {
                let payload = {
                    countryId: this.defaultCountry.id,
                    type: this.getType
                }
                this.$store.dispatch('category/getTrendingHashTags', payload);
            },
            getAllPublishedForBreaking($state) {
                let payload = {
                    page: this.getAllBreakingPage,
                    $state: $state,
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType
                }
                this.$store.dispatch('article/getAllBreakingArticles', payload)
            },
            emitActiveCategory() {
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                this.$store.dispatch("article/setBreakingPageNumber", 1);
                this.getAllPublishedForBreaking();
            },
            updateCountyId(){
                this.emptyCategories();
                this.selectedCategories(this.defaultCountry.id)
                this.$store.dispatch("article/setBreakingPageNumber", 1);
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                this.getTrendingHashtag();
                this.getAllPublishedForBreaking()
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.getType
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            calculate() {
                this.windowWidth = window.innerWidth;
                let divWidth = $(".main-home");
                if (divWidth.offset()) {
                    this.mainHome = divWidth.offset().left + divWidth.width() + 55;
                }
            }
        },
        mounted() {
            this.emptyCategories();
            this.selectedCategories(this.defaultCountry.id)
            this.$store.dispatch("article/setCategoryPageNumber", 1);
            this.$store.dispatch("article/setBreakingPageNumber", 1);
            this.getTrendingHashtag();
            this.calculate();
            setInterval(() => {
                this.calculate();
            }, 100)
        }
    };
</script>

