<template>
    <div class="tool-tip">
        <div class="box  mt-2">
            <div @click="editArticle"  class="tooltip-text">Edit</div>
            <a href="javascript:void(0)" class="tooltip-text" data-toggle="modal" data-target="#archive">Archive</a>
            <div class="arrow arrow-top"></div>
        </div>
        <Archive :cardData="cardData" />
    </div>
</template>

<script>
    import Archive from "../Modals/Archive";
    export default {
        name: "ToolTip",
        components: {
            Archive,
        },
        props: {
            cardData: Object,
        },
        methods:{
            editArticle(){
                localStorage.setItem('cardData',JSON.stringify(this.cardData))
                this.$router.push({name:'writeArticle'})
            },
        }
    }
</script>

<style>

    .box {
        position: absolute;
        right:0;

        width: 98px;
        height: 56px;
        border: 1px solid #ccd8e0;
        background: #fff;
        opacity: 1;
        -webkit-transform: translate(0, 0) scale(1);
        transform: translate(0, 0) scale(1);
        pointer-events: auto;
        border-radius: 4px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
        z-index: 900;
    }


    .arrow {
        position: absolute;
        overflow: hidden;
    }

    .arrow:after {
        content: "";
        background: #fff;
        width: 18px;
        height: 18px;
        position: absolute;
        border: 1px solid #ccd8e0;
        transform-origin: 0 0;
    }

    .arrow-top {
        width: 26px;
        height: 10px;
        left: 83%;
        top: -10px;
        margin-left: -13px;
    }

    .arrow-top:after {
        left: 11px;
        top: 0;
        transform: rotate(45deg);
    }

    .tooltip-text {
        color: #66757f;
        font-size: 13px;
        line-height: 18px;
        padding: 5px 20px;
        white-space: nowrap;
        display: block;
        cursor: pointer;
        text-decoration: none;
    }

    .tooltip-text:hover {
        background-color: #bdbdbd;
        color: white;
    }

</style>