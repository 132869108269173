<template>
    <div class="home-blog">
        <br>
        <div>
            <div v-if="getType==='home' && activeCategory.id!==0">
                <Subscriber :isLogin="isLogin" :category="activeCategory" :subscriber="activeCategory.category_name"
                            :total="activeCategory.subscribers+' subscribers '"/>
            </div>
        </div>
        <div class="row">
            <div class="main-home col-lg-8 col-md-12 col-sm-12 order-xxl-first order-lg-first order-sm-last  order-last">
                <SubHeading category="Breaking" hashTag="" link="/breaking"/>
                <div v-for="breakingData in categoryArticle.breaking" :key="breakingData.id+'_'+Math.random()">
                    <BlogCard :cardData="breakingData" :isEdit="false"/>
                </div>
                <br v-if="categoryArticle.breaking.length===0"/>

                <div v-if="getType==='customHomePage'">
                    <SubHeading category="Subscribers" hashTag="" link="/subscribe"/>
                    <div v-for="subscribedData in categoryArticle.subscribed"
                         :key="subscribedData.id+'_'+Math.random()">
                        <BlogCard :cardData="subscribedData" :isEdit="false"/>
                    </div>
                </div>
                <!--                <SubHeading category="Upcoming" hashTag="" link="/upcoming"/>-->
                <!--                <div v-for="upcomingData in categoryArticle.upcoming" :key="upcomingData.id+'_'+Math.random()">-->
                <!--                    <BlogCard :cardData="upcomingData" :isEdit="false"/>-->
                <!--                </div>-->
                <!--                <br v-if="categoryArticle.upcoming.length===0" />-->

                <div v-for="article in categoryArticle.articles" :key="article.id+'_'+Math.random()">
                    <br/><br/>
                    <SubHeading
                            :category="article.hash_tag_name"
                            :hashTag="article.category.category_name"
                            :link="'/hashtag/'+article.slug"
                            :categoryActive="article.category"
                            :hashTagData="article"
                            :isHashTagData="true"
                            @emitActiveCategory="emitActiveCategory"
                    />
                    <div>
                        <p class="stories">{{article.stories}} Stories</p>
                    </div>

                    <div v-for="(cardData,index) in article.article_category"
                         :key="cardData.id+'_'+Math.random()">
                        <div v-if="cardData.article && index<4">
                            <BlogCard :cardData="cardData.article" :isEdit="false"/>
                        </div>
                    </div>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getArticles"
                ></infinite-loading>
            </div>
            <div :style="' left:'+mainHome+'px'"
                 class="menu-home col-lg-4 col-m-12 col-sm-12 order-xxl-last order-lg-last order-sm-first ordr-first">
                <CountrySelect :isCustom="getType" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                <br>
                <BlogCategory :type="getType" @emitActiveCategory="emitActiveCategory"/>
            </div>
        </div>
        <CommentReply v-if="isLogin"/>
    </div>
</template>

<script>
    import BlogCard from "../components/BlogCard/BlogCard";
    import BlogCategory from "../components/BlogCategory/BlogCategory";
    import CountrySelect from "../components/CountrySelect/CountrySelect";
    import SubHeading from "../components/Headings/SubHeading";
    import CommentReply from "../components/HomeComment/CommnetReply";
    import InfiniteLoading from "vue-infinite-loading";
    import Subscriber from "../components/Subcribers/Subscriber";
    import $ from 'jquery';


    import {mapGetters} from "vuex";

    export default {
        name: "HomeBlog",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Home'
        },
        components: {
            BlogCard,
            BlogCategory,
            CountrySelect,
            SubHeading,
            CommentReply,
            InfiniteLoading,
            Subscriber
        },
        data() {
            return {
                windowWidth: 0,
                mainHome: 0,
                outerHeight: 0
                // category: {
                //     "id": 0,
                //     "user_id": 0,
                //     "country_id": 0,
                //     "category_name": "home",
                //     "level": 0
                // },
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                isLogin: "auth/getIsUserLogin",
                subscribed: "article/getSubscribedArticle",
                getAuthUser: 'auth/getAuthUser',
                categoryArticle: "article/getCategoryArticle",
                pageNumber: "article/getPageNumber",
                activeCategory: "category/getActiveCategory",
                getType: "category/getType",
            }),
        },
        created() {
            this.checkPageType();
        },
        methods: {
            getTrendingHashtag() {
                let payload = {
                    countryId: this.defaultCountry.id,
                    type: this.getType
                }
                this.$store.dispatch('category/getTrendingHashTags', payload);
            },
            emitActiveCategory(category) {
                console.log("emitActiveCategory", category)
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                this.getCategoryBreakingAndUpComing(category);
                this.getArticles();
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.getType
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            updateCountyId(id) {
                this.emptyCategories();
                this.selectedCategories(id);
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                this.getCategoryBreakingAndUpComing(this.activeCategory);
                this.getArticles();
                this.getTrendingHashtag();
            },
            checkPageType() {
                if (this.$route.name === 'customHomePage') {
                    this.$store.dispatch('category/updateType','customHomePage')
                } else {
                    this.$store.dispatch('category/updateType','home')
                }
            },
            getCategoryBreakingAndUpComing(category) {
                let payload = {
                    categoryId: category.id,
                    countryId: this.defaultCountry.id,
                    type: this.getType
                };
                this.$store.dispatch(
                    "article/getCategoryBreakingAndUpComingArticle",
                    payload
                );
            },
            getArticles($state) {
                let payload = {
                    page: this.categoryArticle.page,
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType,
                    $state: $state,
                };
                this.$store.dispatch("article/getCategoryArticle", payload);
            },
            calculate() {
                this.windowWidth = window.innerWidth;
                let divWidth = $(".main-home");
                if (divWidth.offset()) {
                    this.mainHome = divWidth.offset().left + divWidth.width() + 55;
                }
            }
        },
        mounted() {
            this.emptyCategories();
            this.selectedCategories(this.defaultCountry.id);
            this.$store.dispatch("article/setCategoryPageNumber", 1);
            this.getCategoryBreakingAndUpComing(this.activeCategory);
            this.getTrendingHashtag();
            this.calculate();
            setInterval(() => {
                this.calculate();
            }, 100)
        },
    }
</script>

<style>
    .stories {
        font-size: 15px;
    }
</style>