<template>
    <div class="skim-modal">

        <!-- Modal -->
        <div class="modal fade" :id="modelId" data-backdrop="static" data-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content skimModalData">
                    <div class="modal-header">
                        <button @click="closeModel" type="button" class="close text-dark">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul id="homeSkim">
                            <li v-for="skim in skims" :key="skim.id">{{skim.text}}</li>
                        </ul>
                        <div class="text-center mb-xl-5">
                            <div v-if="isLogin" class="skim-helpful">
                                <h3 class="mb-4">Is skim helpful for you ?</h3>
                                <div>
                                    <button @click="skimRating(true)" class="btn btn-outline-dark mr-3 px-4">Yes
                                    </button>
                                    <button @click="skimRating(false)" class="btn btn-outline-dark px-4">No</button>
                                </div>
                            </div>
                            <div class="col-xl-5 col-sm-6  m-auto mt-1" v-if="!isLogin">
                                <button class="btn  commentUserButton shadow-none"
                                        data-toggle="modal" data-target="#LoginModal">
                                    <span class="sign-in-text-color  text-muted">Sign in to give review </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class=" modal-footer skim-footer justify-content-between">
                        <h5>Skim Helpful for Users {{article.total_percentage_skim}}% </h5>
                        <router-link :to="{ name: 'Details', params: { slug: article.slug, articleId:article.id } }"
                                     data-dismiss="modal" aria-label="Close" class="skim-detail">Article Detail
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "skim",
        data() {
            return {
                isCount: false,
                count: 0,
            }
        },
        props: {
            article: Object,
            skims: Array,
            modelId: String,
            isSkim: Boolean
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser'
            }),
        },
        methods: {
            closeModel() {
                this.$emit('emitCloseModel', false)
            },
            skimRating(rating) {
                let payload = {
                    is_like: rating,
                    article_id: this.article.id
                }
                this.$store.dispatch('article/skimLikeDislike', payload)
            },
            countViews() {
                if (this.count >= 0 && this.count <= 10 && this.isSkim) {
                    this.count++;
                }
                if (this.count === 10 && this.isSkim) {
                    this.isCount = true;
                }
                if (this.count === 11 && this.isCount && this.isSkim) {
                    let payload = {
                        slug: this.article.slug
                    }
                    this.$store.dispatch('article/addArticleSkimCount', payload).then(response => {
                        this.article.skim_count = response.data.skim_count;
                        this.isCount = false
                        this.$emit("emitIsSkimData",response.data);
                    })
                }
            },
            addCountTenSecond() {
                setInterval(() => {
                    this.countViews();
                }, 1000)
            }
        },
        mounted() {
            this.addCountTenSecond();
        }
    }
</script>

<style>
    #homeSkim {
        padding-inline: 20px;
    }

    #homeSkim li {
        font-size: 19px;
    }

    /*.modal-dialog {*/
    /*    max-width: 50%;*/
    /*}*/

    .skimModalData {
        height: 600px;
        overflow: scroll;
    }

    .close {
        opacity: 1;
    }

    .thumbs-icon {
        color: #aaa;
    }

    .skim-footer {
        background-color: #d7d4d4;
        font-size: 19px;
    }

    .skim-footer .skim-detail {
        color: #2c3e50;
    }

    .skim-modal .modal-header {
        border-bottom: 0px !important;
    }
</style>