<template>
    <div class="subHeading">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-sx-12">
                <div class="child-tag mb-1">
                    <div class="sub-heading">
                        <h5 class="m-0 heading text">
                            <router-link @click.native="getDetailHashTag"  class="text-dark"  :to="link">
                                {{category}}
                            </router-link>
                        </h5>
                    </div>
                    <div class="hashtag ml-sm-4">
                        <p v-if="hashTag" class="mb-0 cursor text-dark" @click="activeCategory">
                            {{hashTag}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import StoriesCount  from "../Counts/StoriesCount";
    export default {
        name: 'SubHeading',
        components: {
            // StoriesCount,
        },
        props: ['category', 'hashTag', 'link', 'categoryActive','hashTagData','isHashTagData'],
        methods: {
            activeCategory() {
                this.$store.dispatch("category/updateActiveCategory", this.categoryActive)
                this.$emit('emitActiveCategory', this.categoryActive);
            },
            getDetailHashTag(){
                if(this.isHashTagData){
                    this.$store.dispatch('article/setHashTagData',this.hashTagData)
                }
            }
        }
    }
</script>

<style scoped>
    .child-tag {
        display: flex;
        align-items: center;
        padding: 5px 2px 0px;
        border-bottom:2px solid #eaeef1;
    }

    .heading a {
        cursor: pointer;
        text-decoration: none;
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        line-height: 1.2;
        font-size: 24px;
        color: gray;
        box-sizing: border-box;
        display: flex;
        position: relative;
    }
    .sub-heading {
        width: auto;
        padding: 0 10px;
        transform: skew(-30deg);
        border-left: 4px solid #acadb0;
        border-right: 4px solid #acadb0;
        background-color: #f5f5f5;
        margin-left: 10px;
    }

    .sub-heading .text {
        width: 100%;
        padding-inline: 3px;
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        transform: skew(30deg);
    }
    .hashtag {
        margin-top: 2px;
        padding:2px 5px;
    }
    .hashtag:hover {
        background-color: #f5f5f5;
        border-radius: 5px;
        transition: all 0.4s ease-in;
    }

</style>