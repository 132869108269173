<template>
    <div>
        <SubHeading category="All Upcoming Articles" link=""/>
        <div class="row mt-4">
            <div class="col-lg-8 col-md-12 col-sm-12 order-lg-first order-md-last">
                <div v-for="cardData in getAllUpcomingArticles" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :cardData="cardData"/>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getAllArticlesForUpcoming"
                ></infinite-loading>
            </div>
            <div class="col-lg-4 col-m-12 col-sm-12 order-lg-last order-md-first">
                <CountrySelect :isCustom="type" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                <br>
                <BlogCategory @emitActiveCategory="emitActiveCategory"/>
            </div>
        </div>
    </div>
</template>

<script>
    // import Card from "../components/Cards/Card";
    import BlogCard from "../components/BlogCard/BlogCard";
    import BlogCategory from '../components/BlogCategory/BlogCategory'
    import CountrySelect from '../components/CountrySelect/CountrySelect'
    import SubHeading from "../components/Headings/SubHeading";
    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex';

    export default {
        name: "AllUpcoming",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Upcoming Article'
        },
        components: {
            BlogCard,
            SubHeading,
            InfiniteLoading,
            BlogCategory,
            CountrySelect
        },
        data() {
            return {
                category: {
                    "id": 0,
                    "user_id": 0,
                    "country_id": 0,
                    "category_name": "home",
                    "level": 0
                },
            };
        },
        computed: {
            ...mapGetters({
                getAllUpcomingArticles: 'article/getAllUpcomingArticles',
                getAllUpcomingPage: 'article/getAllUpcomingPage',
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            })
        },
        methods: {
            getAllArticlesForUpcoming($state) {
                let payload = {
                    page: this.getAllUpcomingPage,
                    $state: $state,
                    countryId: this.defaultCountry.id
                }
                this.$store.dispatch('article/getAllUpcomingArticles', payload)
            },
            emitActiveCategory(category) {
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                this.category = category
                this.getCategoryBreakingAndUpComing(category);
                this.getArticles();
            },
        },
        mounted() {
            this.getAllArticlesForUpcoming()
        }
    };
</script>

