<template>
    <div class="hide">
        <div class="row">
            <div class="col-lg-8 col-md-11 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"
                />
                <div class="">
                    <h1 class="firstHeading">{{articleDetail.title}}</h1>
<!--                    <h2 class="secondHeading mb-2">{{articleDetail.subTitle}}</h2>-->

                </div>

            </div>


            <div class="col-lg-8 col-md-11 col-sm-8  mx-auto sec-div">

                <!---Footer--->
                <div class="social-icon  social-icon-blog d-flex  mt-sm-0">
                    <div class="mb-0 w-25 social-views">{{articleDetail.total_views_skim}} Views</div>
<!--                    <div class="badge-detail">-->
<!--                        <table>-->
<!--                            <tbody class="table-body">-->
<!--                            <tr>-->
<!--                                <td>-->
<!--                                    <label class="mr-1 mb-0 small-head supportive ml-5">-->
<!--                              <span-->
<!--                                      class="badge"-->
<!--                                      style="background-color: #28a745; color: #fff">-->
<!--                                  Supportive-->
<!--                      </span>-->
<!--                                    </label>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!--                      <span-->
<!--                              class="badge"-->
<!--                              style="color: #fff; background-color: #343a40"-->
<!--                      >Neutral-->
<!--                      </span>-->
<!--                                    </label>-->
<!--                                </td>-->

<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!--                                    <span class="badge"-->
<!--                                          style="color: #fff; background-color: #dc3545">-->
<!--                                    Critique-->
<!--                      </span></label>-->

<!--                                </td>-->
<!--                            </tr>-->

<!--                            <tr class="text-center">-->
<!--                                <td class="count"><label-->
<!--                                        class="font-sd ml-5 first">{{articleDetail.total_supportive}}</label></td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_neutral}}</label>-->
<!--                                </td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_critique}}</label>-->
<!--                                </td>-->
<!--                            </tr>-->

<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->

                    <div class="progress w-50">
                        <div class="progress-bar bg-success" role="progressbar" :style="{'width':articleDetail.total_supportive_percentage+'%'}">
                            {{articleDetail.total_supportive_percentage}}%
                        </div>
                        <div class="progress-bar bg-dark" role="progressbar" :style="{'width':articleDetail.total_neutral_percentage+'%'}">
                            {{articleDetail.total_neutral_percentage}}%
                        </div>
                        <div class="progress-bar bg-danger" role="progressbar" :style="{'width':articleDetail.total_critique_percentage+'%'}">
                            {{articleDetail.total_critique_percentage}}%
                        </div>
                    </div>

                    <!-- <div class="socail-link">
                        <table class="blog-info info-wrapper mb-2 w-100">
                            <tbody>
                            <tr>
                                <td class="">
                                    <a href="https://theworded.com/testing/www/html/public/index.php/userprofile/9c67ce0e07f42c96be4d2b38c28bd1af"
                                    >
                                        <label
                                                class="mb-0 shareText"
                                                style="color: gray; font-size: 16px"
                                        >Share:
                                        </label>
                                    </a>
                                    <a @click="shareArticle(articleDetail)" class="social-icon" target="_blank">
                                        <i class="fa-brands fa-facebook-f" style="color: gray;width: 17px;font-size: 15px;text-align: center;"></i>
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=" target="_blank" class="social-icon">
                                        <i class="fa-brands fa-twitter" style="
                          color: gray;
                          width: 20px;
                          font-size: 15px;
                          text-align: center;
                        ">
                                        </i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>

            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"

    export default {
        name: "Hide",
        components: {userProfileDetail},
        props: {
            articleDetail: Object,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>


<style scoped>
    .blog-info .shareLinks {
        display: inline-flex;
        align-items: center;
    }

    .blog-info .blog-small-image {
        width: 20px;
    }

    .social-icon {
        font-size: 15px;
    }

    .iconShare {
        font-size: 5px;
    }

    .shareText {
        font-size: 12px;
        color: gray;
    }

    .firstHeading {
        margin-top: 7px;
        overflow-wrap: break-word;
        font-size: 32px;
        max-height: 140px;
        font-family: "Merriweather";
        font-weight: 400;
        list-style: 1.2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .secondHeading {
        overflow-wrap: break-word;
        font-size: 28px;
        height: 84px;
        margin-bottom: 0px !important;
        font-family: Oswald !important;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .box-image a {
        display: inline-block;

    }

    .box-image img {
        height: 721px;
    }

    .blog-info .social-icon .fa-brands {
        color: gray;
        width: 20px;
        font-size: 14px;
        text-align: center;
    }


    .social-icon .view {
        font-size: 16px;
        color: gray;
        top: -1.8em;
    }

    .socail-icon {
        align-items: flex-end;
    }

    .social-icon i {
        color: gray;
        width: 17px;
        font-size: 15px;
        text-align: center;
    }

    .socail-link {
        width: 25%;
        text-align: end;
    }

    .title {
        font-size: 12px;
    }

    .small-head .badge {
        font-size: 10px !important;
    }

    .badge-detail {
        display: flex;
        justify-content: center;
    }

    .social-icon {
        font-size: 13px;
    }
    .social-icon-blog{
        justify-content: space-between;
    }
    @media only screen and (min-width: 280px) and (max-width: 319px) {
        .small-head .badge {
            font-size: 9px !important;
        }
    }
</style>