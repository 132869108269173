<template>
    <div class="user">

        <div class="row mt-4" v-for="users in getSearchUsers" :key="users.id+'_'+Math.random()">
            <div class="col-9 col-xxl-5 col-lg-5 col-md-8  col-sm-8  mx-auto">
                <div class="row">
                    <div class="userImage col-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3">
                        <a href="">
                            <img v-if="users.profile_pic" class="user-profile mr-5 rounded-circle" :src="users.profile_pic"
                                >
                        </a>
                    </div>
                    <div class="userName col-9 col-xxl-9 col-lg-9  col-md-9 col-sm-9">
                        <h3 class="mb-0">{{users.name}}</h3>
                        <div v-if="isLogin">
                            <Subscriber type="searchUser"
                                        :loginUserId="getAuthUser.id"
                                        :userId="users.id"
                                        :totalUserSubscribers="users.total_user_subscribers"
                                        :IsSubscribe="users.is_subscribe"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Subscriber from "../components/User/Subscriber";

    export default {

        name: 'user',
        data() {
            return {}
        },
        components: {Subscriber},
        computed: {
            ...mapGetters({
                getSearchUsers: "article/getSearchUsers",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        },
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>
   
</style>