import axios from "../../axios.js";

export default {
    async login({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/auth/login', payload).then((response) => {
                commit('SET_AUTH_USER', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async logout({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/auth/logout', payload).then((response) => {
                commit('LOGOUT_AUTH_USER')
                resolve(response.data)
            }).catch((error) => {
                commit('LOGOUT_AUTH_USER')
                reject(error)
            })
        })
    },
    async routeLogout({commit}) {
        commit('LOGOUT_AUTH_USER')
    },


    async facebookLogin({commit}, token) {
        return new Promise((resolve, reject) => {
            axios.get(`https://graph.facebook.com/v8.0/me?access_token=${token}`).then((response) => {
                console.log("commit", commit)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateUserProfile({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/profile', payload).then((response) => {
                commit('UPDATE_USER_PROFILE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },


}
