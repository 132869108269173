<template>
    <div id="articleDetail">
        <Default v-if="articleDetail.layout==='default'" :articleDetail="articleDetail"/>
        <Hide v-if="articleDetail.layout ==='hide'" :articleDetail="articleDetail"/>
        <ArticleWidth v-if="articleDetail.layout==='article_width'" :articleDetail="articleDetail"/>
        <Banner v-if="articleDetail.layout==='banner'" :articleDetail="articleDetail"/>
        <OverSized v-if="articleDetail.layout==='oversized'" :articleDetail="articleDetail"/>
        <div class="row skim-row">
            <div :class="layoutDetailPageClass()">
                <div class="d-flex justify-content-end" style="column-gap: 10px;">
                        <div id="skimToogle" class="d-flex ">
                            <b class="font-weight-bold pr-2">Skim</b>
                            <b-form-checkbox @change="highlightedText" name="check-button" switch>
                            </b-form-checkbox>
                        </div>
                        <div class="sahre-iconst">
                       <a href="https://theworded.com/testing/www/html/public/index.php/userprofile/9c67ce0e07f42c96be4d2b38c28bd1af">
                                <label class="mb-0 shareText font-weight-bold" style="color:#2c3e50; font-size: 14px">Share:</label>
                                    </a>
                                    <a @click="shareArticle(articleDetail)" class="social-icon d-inline-block mr-2" target="_blank">
                                    <img src="../assets/images/facebook.png" height="17px">
                                        <!-- <i class="fa-brands fa-facebook-f" style="color: gray;width: 17px;font-size: 14px;text-align: center;"></i> -->
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=" target="_blank" class="social-icon d-inline-block">
                                        <img src="../assets/images/twitter.png" height="17px">
                                        <!-- <i class="fa-brands fa-twitter" style="color: gray;width: 20px;font-size: 14px;text-align: center;"></i> -->
                                    </a>
                        </div>
                </div>
             
                <div class="row">
                    <div class="col-md-12">
                    <div id="skimContent" class="blog-detail mt-2 mb-2" style="background-color: #ebebea;">
                        <span></span>
                    </div>
                    </div>
                </div>
                <div class="artical">
                    <div class="article-content" v-html="articleDetail.content"></div>
                    <div class="divider">
                        <hr class="line mx-auto" style="margin-top: 65px"/>
                    </div>
                    <br/>
                    

                    <div class="col-12 pt-4 mx-auto">
                        <div class="top-nav">
                        <nav>
                            <ul class="nav">
                                <li class="nav-item ml-2" v-for="articleCategory in articleDetail.article_category"
                                    :key="articleCategory.id+'_'+Math.random()" >
                                    <router-link to="/home" class="nav-link" @click.native="activeClass(articleCategory.category)">
                                        {{articleCategory.category.category_name}}
                                    </router-link>
                                </li>
                            </ul>
                        </nav>
                        </div>

                        <br/>
                        <div class="space">
                            <ul class="nav">
                                <li class="nav-item ml-2" v-for="articleCategory in articleDetail.article_category"
                                    :key="articleCategory.id+'_'+Math.random()">
                                    <router-link :to="'/hashtag/'+articleCategory.hashtag.slug" @click.native="activeClass(articleCategory.category)" class="nav-link space-nav-link">
                                        {{articleCategory.hashtag.hash_tag_name}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="articleId">
            <UserComments v-if="isLogin" :articleId="articleId"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Default from "../components/customizeLayout/Default";
    import ArticleWidth from "../components/customizeLayout/ArticleWidth";
    import Banner from "../components/customizeLayout/Banner";
    import Hide from "../components/customizeLayout/Hide";
    import OverSized from "../components/customizeLayout/Oversized";
    import $ from "jquery";

    import UserComments from "../components/UserComments/UserCommentsBox"

    export default {
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Article Detail'
        },
        components: {
            OverSized, Default, UserComments, ArticleWidth, Banner, Hide
        },
        data() {
            return {
                checked: false,
                isCount: false,
                count: 0,
                articleCount: 1,
                isScroll: false,
                articleId: localStorage.getItem('articleId') ? parseInt(localStorage.getItem('articleId')) : null
            }
        },
        methods: {
            getArticleDetail() {
                let payload = {
                    slug: localStorage.getItem('articleSlug'),
                }
                if (payload.slug) {
                    this.$store.dispatch('article/articleDetail', payload).then((response) => {
                        this.showSkim()
                        this.articleId = response.data.id
                    })
                }
            },
            checkCustomizeLayout(article) {
                console.log(article);
            },
            showSkim() {
                var text = $('.article-content skim');
                console.log("checking skim12312", text)
                if (text.length > 0) {
                    $('.skimButton').removeClass('d-none');
                }
            },
            highlightedText() {
                var text = $('.article-content skim');
                var skim = $('#skimContent span');
                if (skim.hasClass('highLight')) {
                    skim.removeClass('highLight');
                    skim.removeAttr("style");
                    skim.empty();
                } else {
                    for (var i = 0; i < text.length; i++) {
                        console.log('checking', text.length)
                        skim.append("<li>" + text[i].innerText + "</li>");
                        skim.addClass('highLight');
                    }
                }
            },
            getArticleViews() {
                window.onscroll = () => {
                    this.isScroll = true;
                }
            },
            countViews() {
                if (this.count >= 0 && this.count <= 15) {
                    this.count++;
                }
                if (this.count === 15) {
                    this.isCount = true;
                }
                if (this.count === 16 && this.isCount && this.isScroll) {
                    console.log("add View");
                    let payload = {
                        slug: this.articleDetail.slug
                    }
                    this.$store.dispatch('article/addArticleViews', payload).then(() => {
                        this.isCount = false
                    })
                }
            },
            addCountFifteenSecond() {
                setInterval(() => {
                    this.countViews();
                }, 1000)
                return this.count;
            },
            layoutDetailPageClass(){
                if(this.articleDetail.layout==='default'){
                    return 'col-12 col-lg-8 col-md-12 col-sm-12 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  mx-auto   skimButton  blog-detail';
                }
                if(this.articleDetail.layout==='hide'){
                    return 'col-12 col-lg-8 col-md-8 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  mx-auto   skimButton  blog-detail';
                }
                if(this.articleDetail.layout==='article_width'){
                    return 'col-12 col-lg-8 col-md-8 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3 mt-3 mx-auto skimButton  blog-detail';
                }
                if(this.articleDetail.layout==='banner'){
                    return 'col-12 col-lg-8 col-md-8 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  mx-auto   skimButton  blog-detail';
                }
                if(this.articleDetail.layout==='oversized'){
                    return 'col-12 col-lg-8 col-md-8 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  mx-auto   skimButton  blog-detail';
                }
            },
            activeClass(category) {
                this.$store.dispatch("category/updateActiveCategory", category);
            },


        },
        computed: {
            ...mapGetters({
                articleDetail: 'article/getArticleDetails',
                isLogin: "auth/getIsUserLogin",
            }),
        },
        mounted() {
            if (this.$route.params.slug) {
                localStorage.setItem('articleId', this.$route.params.articleId)
                localStorage.setItem('articleSlug', this.$route.params.slug)
            }
            this.getArticleDetail();
            this.getArticleViews();
            this.addCountFifteenSecond()

        },

    };
</script>

<style scoped>
    .skim-row {
        margin-top: 40px;
    }

    .artical {
        margin-bottom: 100px;
        font-size: 19px;
        line-height: 2;
        font-weight: 500;
    }

    .artical .article-content  p {
        font-size: 19px;
        line-height: 2;
        font-weight: 400;
        
        font-family: Merriweather;
    }
    .divider .line {
        border-top: 1px solid black !important;
        width: 20% !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .top-nav .nav .nav-item .nav-link {
        color: grey !important;
        font-size: 18px;
    }

    .nav-link {
        padding: 0px;
    }

    .artical .space {
        display: flex;
        justify-self: flex-start;
        border-bottom: 2px solid black;
    }

    .space a {
        font-size: 18px;
        margin: 0 5px 10px;
    }

    .space a:hover {
        color: black;
    }

    .divider {
        margin-left: 10px;
    }
    .space .space-nav-link{
     color: #007bff!important;
   }
  .skimButton  #skimToogle  b{
    font-size:14px;
   }



    #skimContent span li {
        font-size: 19px;
        padding-left: 15px;
    }


</style>