<template>
  <div class="articles">

   
<div class="row mt-4">
      <div class="col-lg-8 col-md-12 col-sm-12 order-xxl-first order-lg-first order-sm-last  order-last">
          <div v-for="breakingData in getSearchArticles" :key="breakingData.id+'_'+Math.random()">
            <BlogCard  :cardData="breakingData"/>
          </div>
      </div>
    </div>
<!--    <infinite-loading-->
<!--            spinner="spiral"-->
<!--            @infinite="getArticles"-->
<!--    ></infinite-loading>-->
  </div>
</template>

<script>

// import Card from "../components/Cards/Card";
import BlogCard from "../components/BlogCard/BlogCard";
// import InfiniteLoading from "vue-infinite-loading";
import { mapGetters } from "vuex";

export default {
  name: "articles",
  components: {
    // Card,
      BlogCard
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      defaultCountry: "country/defaultCountry",
        getSearchArticles: "article/getSearchArticles",
    }),
  },
  methods: {

  },
  mounted() {

  },
};
</script>
<style scoped>

</style>
