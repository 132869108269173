<template>
    <div class="HomePageComments">
        <div class="commentMain">
            <div class="innerSection" v-on:click="checkToggle">
                <span>Comments</span>
                <i class="fas fa-angle-double-down" v-if="isToggle"></i>
                <i class="fas fa-angle-double-up" v-if="!isToggle"></i>
            </div>
            <div class="commentBox" v-if="isToggle">
                <div class="row">
                    <ul class="nav reply-nav w-100">
                        <li :class="+isAllSend?'nav-item active':'nav-item'">
                            <a href="javascript:void(0)" @click="checkSend(true)" class="nav-link">Sent</a>
                        </li>
                        <li :class="+!isAllSend?'nav-item active':'nav-item'">
                            <a href="javascript:void(0)" @click="checkSend(false)" class="nav-link">
                                Received</a></li>

<!--                        <li :class="+isAll?'nav-item all-list active':'nav-item all-list'">-->
<!--                            <a href="javascript:void(0)" class="nav-link">All</a>-->
<!--                        </li>-->
<!--                                                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Writer's Reply's</a></li>-->
                    </ul>
                    <div class="col-lg-12 col-sm-12">
                        <PaginateAllSendComments v-if="isAllSend"/>
                        <PaginateAllReceivedComments v-if="!isAllSend"/>

                        <!--                        <CommentsNavbar :navBarData="navBarArray"/>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import CommentsNavbar from "../Navbars/CommentsNavbar";
    import PaginateAllReceivedComments from "../HomeComment/PaginateAllReceivedComments";
    import PaginateAllSendComments from "../HomeComment/PaginateAllSendComments";


    export default {
        name: "CommentReply",
        components: {
            // CommentsNavbar,
            PaginateAllReceivedComments,
            PaginateAllSendComments
        },
        props:{
        },

        data() {
            return {
                isToggle: false,
                isCheck: false,
                isAll: true,
                isAllSend: true,
                isAllReceived: true,
                navBarArray: [
                    {id: 1, title: "All"},
                    // {id: 2, title: "Writer reply's"},
                ],

            };
        },

        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            checkSend(val) {
                this.isAllSend = val
            },
            isActiveClass() {
                return 'active'
            }
        }

    };
</script>

<style >
    .commentMain {
        width: 720px;
        position: fixed;
        bottom: 0;
        z-index: 2;
    }
    .innerSection {
        border-radius: 10px 10px 0 0;
        display: flex;
        width: 300px;
        font-weight: bold;
        margin-left: auto;
        justify-content: space-between;
        align-items: center;
        color: #000;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        background-color: #f6f6f6;
        cursor: pointer;
    }

    .commentBox {
        background-color: #f6f6f6;
        border-radius: 10px 0 0 0;
        max-height: 500px;
        min-height: 350px;
        width: 100%;
        padding: 10px 20px;
        overflow-y: auto;
    }

    .filter {
        width: 50px;
        margin-left: -46px;
    }

    .reply-nav {
        padding: 20px;
        justify-content: center;
        align-items: center;
    }

    .reply-nav .nav-item {
     margin-right:30px;
    }

    .reply-nav .all-list {
        margin-inline-start: 267px;
    }

    .reply-nav .nav-item .nav-link {
        color: black !important;
        font-size: 14px;
        font-weight: 400 !important;
        padding: 0 5px;
    }

    .reply-nav .nav-item .nav-link:hover {
        color: black !important;
        background-color: #eaeef1 !important;
        border-bottom: 2px solid #aaa !important;
        font-size: 14px;
        font-weight: 400 !important;
        padding: 0 5px;

    }

    .reply-nav .active {
        color: black !important;
        background-color: #eaeef1 !important;
        border-bottom: 2px solid #aaa !important;
    }

</style>
