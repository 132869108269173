<template>
    <div class="tweet">


        <!-- Modal -->
        <div class="modal fade" id="tweetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title tweetTitle" id="exampleModalLabel">Insert Tweet</h4>
                        <button @click="clickButtonToCloseModal('tweetModal')" type="button" class="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
                    <div class="modal-body">
                        <div class="form-group">
                            <input
                                    v-model = 'tweetLink'
                                    v-validate="'required'"
                                    :class="{'normalInput': true, 'text-danger': errors.has('tweet link') }"
                                    name="tweet link"
                                    value=""
                                    id="tweetEmbed"
                                    type="text" placeholder="Tweet Link" class="form-control" />
                            <i v-show="errors.has('tweet link')" class="fa fa-warning"></i>
                            <span v-show="errors.has('tweet link')"
                                  class="text-danger">{{ errors.first('tweet link') }}</span>
                            <i v-if="isValidate" class="fa fa-warning"></i>
                            <span v-if="isValidate" class="text-danger">{{ message }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="tweetBtn btn-secondary text-muted">Embed
                            Tweet
                        </button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "TweetModal",
        data(){
            return{
                tweetLink :"",
                isValidate:false,
                message : ''
            }
        },
        methods:{
            clickButtonToCloseModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            validateBeforeSubmit() {
                console.log("click");
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let tweetLink = $('#tweetEmbed').val();
                        let twitterClass = $(tweetLink).attr('class');
                        if(twitterClass === 'twitter-tweet'){
                            this.isValidate = false
                            this.$emit('emitTweetLink',tweetLink)
                            this.clickButtonToCloseModal('tweetModal')
                            this.tweetLink = ''
                        } else{
                            this.isValidate = true;
                            this.message = 'Please Enter valid Embed Code!'
                        }
                    }
                });
            },
        },
        watch:{
            tweetLink(val){
                if(val===""){
                    this.isValidate = false
                }
            }
        }
    }
</script>

<style scoped>

    .modal-dialog {
        width: 700px;
    }

    .tweetTitle {
        font-size: 1.35rem;
        font-weight: 500;
    }

    .tweetBtn {
        background-color: #f5f5f5 !important;
        border: 1px solid #007bff !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        border-radius: 0.25rem
    }
</style>