export default {

    categories: [],
    customCategories: [],
    worldCategories: [],
    worldHashTags: [],
    countryCategories: [],
    countryHashTags: [],
    trendingHashTags: [],
    settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    },
    type: (localStorage.getItem('type')) ? JSON.parse(localStorage.getItem('type')) : 'home',
    customSelectedCategory: (localStorage.getItem('setCategory')) ? JSON.parse(localStorage.getItem('setCategory')) : {
        id: '',
        category_name: ''
    },
    activeCategory: (localStorage.getItem('activeCategory')) ? JSON.parse(localStorage.getItem('activeCategory')) : {
        "id": 0,
        "user_id": 0,
        "country_id": 0,
        "category_name": "home",
        "level": 0
    },
    homeCategory: {
        "id": 0,
        "user_id": 0,
        "country_id": 0,
        "category_name": "home",
        "subscribers": 0,
        "level": 0
    },
    paginate: {
        links: {
            first: null,
            last: null,
            prev: null,
            next: null
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: 16,
            to: 1,
            total: 1,
            custom: null
        },
    }
}
