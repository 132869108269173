<template>
    <div class="blog-card">
        <div class="blog-card pt-md-3 ">
            <div class="custom-tooltip" v-if="isEdit">
                <i @click="checkToggle" class="fa fa-ellipsis-v tooltip-icon" aria-hidden="true"></i>
                <ToolTip v-if="isToggle" :cardData="cardData"/>
            </div>
            <router-link :to="isEditRoute()"
                         target="_top" class="row cursor pt-4" @click="detail">
                <div class="col-12 col-md-8 col-lg-7 col-xl-8 col-sm-12 order-md-first order-sm-first order-first">
                    <div class="body-card">
                        <h5 class="card-title  fw-bold">{{cardData.title}}</h5>
                        <p v-if="skimData.length>0" class="card-text mb-0 ">{{skimData[0].text}}</p>
                        <div class="footer d-flex pt-2">
                            <div class="profile-image">
                                <router-link :to="{name:'userProfile'}" @click.native="profile" target="_top" class="cursor" >
                                    <img :src="cardData.user.profile_pic" alt="profile-image"
                                         class="image img-fluid mr-2">
                                    <span class="profile-name fw-bold">{{cardData.user.name}}</span>&nbsp;
                                </router-link>
                                <span class="footer-deatil">
                                        <span class="dot"></span>
                                        {{cardData.total_views_skim}} Views
                                        <span class="dot"></span> {{cardData.created_at}}
                                            <a href="#" class="badge badge-pill bg p-2"></a>

                                            <img style="filter:grayscale(100%);" v-if="cardData.video_url" src="/img/youtube-logo.898d3054.png"
                                                 alt="Youtube link" width="18px" height="10px"
                                                 class="mt-0 pl-1 cursor youtube-link-icon">
                                     </span>
                            </div>
                            <a href="javascript:void(0)"
                               onclick="return false;"
                               target="poppup" @click="highlightedText" v-if="skimData.length>0" class="skim-modal-btn">
                                <u>SKIM</u>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 col-sm-12 col-lg-5 col-xl-4 card order-md-last order-sm-last order-last pt-md-0">
                    <div class="home-blog-image">
                    <VideoOrImageFrame
                            :videoUrl="cardData.video_url"
                            :imageUrl="cardData.thumbnail"
                            imageClass="card-img-top"

                    />
                    </div>
                </div>
            </router-link>
        </div>
        <SkimModal :isSkim="isSkim" :modelId="'skim-modal-btn-'+cardData.id" :article="cardData" @emitIsSkimData="emitIsSkimData" @emitCloseModel="emitCloseModel"
                   :skims="skimData"/>
    </div>
</template>

<script>
    import SkimModal from "../Modals/SkimModal";
    import ToolTip from "../ArticalDots/ToolTip";
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";

    import $ from "jquery";

    export default {
        name: "BlogCard",
        data() {
            return {
                skimData: [],
                isSkim: false,
                isDetail: false,
                isProfile: false,
                isToggle: false,
            }
        },
        components: {
            SkimModal, ToolTip, VideoOrImageFrame
        },
        props: {
            cardData: Object,
            isEdit: Boolean
        },
        methods: {
            emitCloseModel() {
                $('#skim-modal-btn-' + this.cardData.id).modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data){
                this.IsSkim = false
                this.cardData.total_views_skim = data.total_views_skim
            },
            highlightedText() {
                this.isSkim = true;
                $('#skim-modal-btn-' + this.cardData.id).modal('show');
            },
            skimShowOnHomePage() {
                var skimDataArray = [];
                if (this.cardData.content !== "") {
                    var content = $('<html>').html(this.cardData.content).find('SKIM');
                    content.map((e) => {
                        skimDataArray.push({id: e, text: $(content[e]).text()});
                    });
                    this.skimData = [];
                    skimDataArray.map((x) => {
                        this.skimData.push({id: x.id, text: x.text})
                    })
                }
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                // if(this.isEdit){
                //     localStorage.setItem('cardData',JSON.stringify(this.cardData))
                //     this.$router.push({name:'writeArticle'})
                //     return
                // }
                // console.log("profile");
                // this.isDetail = true;
                // if(this.isDetail === true && this.isProfile === false && this.isSkim === false){
                localStorage.setItem('profile', JSON.stringify(this.cardData.user))
                // this.$router.push('/user/profile');
                // }
            },
            detail() {
                // if(this.isEdit){
                //     localStorage.setItem('cardData',JSON.stringify(this.cardData))
                //     this.$router.push({name:'writeArticle'})
                //     return
                // }
                // console.log("detail");
                // this.isProfile = true;
                // if(this.isDetail === false && this.isProfile === true && this.isSkim ===false){
                localStorage.setItem('articleId', this.cardData.id)
                localStorage.setItem('articleSlug', this.cardData.slug)
                // this.$router.push({ name: 'Details', params: { slug: this.cardData.slug, articleId:this.cardData.id } })
                // }
            },
            isEditRoute(){
                if(this.isEdit){
                    localStorage.setItem('isEdit',JSON.stringify(true))
                    localStorage.setItem('cardData',JSON.stringify(this.cardData))
                    return { name: 'writeArticle'}
                }
                return { name: 'Details', params: { slug: this.cardData.slug, articleId:this.cardData.id } };
            }
        },
        mounted() {
            this.skimShowOnHomePage();
        }
    }
</script>

<style scoped>

    .custom-tooltip {
        text-align: end;
    }

    .blog-card {
        color: grey !important;
        font-family: 'Roboto' !important;
    }

    .card {
        border: none;
    }

    .profile-image .image {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .card-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: black;
        font-weight: bold;
        font-size: 18px;
    }

    .card-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: gray;
        font-size: 16.4px;
    }

    .footer .bg {
        background-color: #F2F2F2;
        color: rgba(117, 117, 117, 1);
    }

.home-blog-image {
    max-width: 230px;
    width:100%;
    max-height:150px;
}
   .home-blog-image  .card-img-top {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    .dot {
        height: 3px;
        width: 3px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .footer .footer-deatil {
        font-size: 12px;
        color: gray;
    }

    .skim-modal-btn {
        position: absolute;
        right: 58px;
        cursor: pointer;
        z-index: 1;
    }

    .skim-modal-btn u {
        color: gray;
        z-index: 1;
        font-size: 14px;
    }

    .routerLink {
        color: gray;
    }

    .routerLink small {
        color: black;
    }

    .profile-name {
        font-size: 13px;
        color: gray;
    }

</style>