<template>
  <div class="search-nav">
    <div class="homeTabsWrapper searchNav  mt-4  mb-5">
      <b-tabs>
        <b-tab v-for="nav in navBarData" @click="showMethod(nav)" :key="nav.id+'_'+Math.random()" :title="nav.title">
          <b-card-text> 
          
            <div v-if="componentPages === 1">
              <articles />
            </div>
            <div v-if="componentPages === 2">
              <user />
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import articles from "../../views/SearchArticles";
import user from "../../views/user";

export default {
  name: "searchNav",
  props: {
    navBarData: Array,
  },

  data() {
    return {
      componentPages: 1,
    };
  },

  methods: {
    showMethod(nav) {
      this.componentPages = nav.id
    },
  },

  //register
  components:{

articles,
user,
  },
};

</script>

<style scoped>


</style>
