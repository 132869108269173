export default {

	SET_AUTH_USER(state,response){

		localStorage.setItem('user',JSON.stringify(response.data.data));
		state.user = response.data.data
		state.isUserLogin = true
		state.token = localStorage.setItem('auth_token',response.data.data.access_token);
		// state.user = localStorage.getItem('user')
	},

	LOGOUT_AUTH_USER(state){
		localStorage.removeItem('user');
		localStorage.removeItem('auth_token');
		state.user = {}
		state.isUserLogin = false
	},
	UPDATE_USER_PROFILE(state,response){
		let user=JSON.parse(localStorage.getItem('user'));
		user.name = response.data.data.name
		user.description = response.data.data.description
		user.profile_pic = response.data.data.profile_pic
		user.sec_1 = response.data.data.sec_1
		user.sec_2 = response.data.data.sec_2
		user.sec_3 = response.data.data.sec_3
		user.socail_user.fb_url = response.data.data.socail_user.fb_url
		user.socail_user.twitter_url = response.data.data.socail_user.twitter_url
		user.socail_user.global_url = response.data.data.socail_user.global_url
		user.socail_user.youtube_url = response.data.data.socail_user.youtube_url
		localStorage.setItem('user',JSON.stringify(user))
	}
}