<template>
    <div>

        <div
                class="modal fade"
                id="customizationMobileThumbnailImageModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static" data-keyboard="false"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="exampleModalLabel">Insert Mobile Thumbnail Image</h4>
                        <button @click="closeModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label id="files-name" class="col-form-label"></label>
                                <input ref="fileInput" @change="previewFiles" type="file"/>
                            </div>

                            <div class="form-group" style="overflow: auto">
                                <label class="form-label uploadGif"
                                >Upload GIFs from giphy.com</label>
                                <br/>
                                <input v-model="customizationHeaderGif"
                                       class="uploadInput col-12"
                                       placeholder="giphy.com Link"
                                       name="gifimg"
                                       value=""
                                       type="text"
                                />
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <button @click="submitHeaderImage" type="button" data-dismiss="modal" aria-label="Close"
                                class="px-3 py-2 text-dark headerImage">
                            Insert Image
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomizationHeaderImageModal",
        data() {
            return {
                headerImage: "",
                headerGif: "",
                customizationHeaderImage: "",
                customizationHeaderGif: ""
            }
        },
        methods: {
            previewFiles(event) {
                let input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.$emit('emitCustomizationMobileImagePreview', e.target.result)
                    }
                    this.customizationHeaderImage = input.files[0];
                    this.$emit('emitCustomizationMobileThumbnailImage', this.customizationHeaderImage)
                    reader.readAsDataURL(input.files[0]);
                }
            },
            submitHeaderImage() {
                if (this.headerGif !== "") {
                    this.$emit('')
                }
            },
            closeModal() {
                this.$emit('closeModal', false)
            }

        }
    };
</script>

<style scoped>
    .modal-content {
        width: 700px;
    }

    .headerImage {
        background-color: #f5f5f5 !important;
        border-color: #007bff;
        border-radius: 5px;
    }

    .headerImage:hover {
        background-color: #f5f5f5 !important;
        outline: #007bff;
        border-radius: 5px;
    }

    .uploadGif {
        font-family: "Raleway", sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.6;
        color: black;
    }

    .uploadInput {
        border-bottom: 1px solid gray;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0.25rem;
        height: calc(2.19rem + 2px);
        padding: 0.375rem 0.75rem;
        outline: none;
    }
</style>
