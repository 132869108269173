<template>
    <div class="caption">
        <!-- Modal -->
        <div class="modal fade" id="captionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title captionTitle" id="exampleModalLabel">Insert caption</h4>
                        <button @click="clickButtonToCloseModal('captionModal')" type="button" class="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="validateBeforeSubmit">
                        <div class="modal-body">
                            <input type="hidden" id="captionImageId" value="">

                            <div class="form-group">
                                <input
                                        v-model = 'caption'
                                        v-validate="'required'"
                                        :class="{'input': true, 'text-danger': errors.has('caption') }"
                                        name="caption"
                                        value=""
                                        id="captionEmbed"
                                        type="text" placeholder="caption" class="form-control" />
                                <i v-show="errors.has('caption')" class="fa fa-warning"></i>
                                <span v-show="errors.has('caption')"
                                      class="text-danger">{{ errors.first('caption') }}</span>
                                <i v-if="isValidate" class="fa fa-warning"></i>
                                <span v-if="isValidate"
                                      class="text-danger">{{ message }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="captionBtn btn-secondary text-muted">Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "captionModal",
        data(){
            return{
                caption :"",
                isValidate:false,
                message : ''
            }
        },
        methods:{
            clickButtonToCloseModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            validateBeforeSubmit() {
                console.log("click");
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let captionEmbed = $('#captionEmbed').val();
                        let captionImageId = $('#captionImageId').val();
                        let payload = {
                            image_id:captionImageId,
                            caption:captionEmbed
                        }
                        this.$emit('emitImageCaption',payload);

                    }
                });
            },
        },
        watch:{
            tweetLink(val){
                if(val===""){
                    this.isValidate = false
                }
            }
        }
    }
</script>

<style scoped>

    .modal-dialog {
        width: 700px;
    }

    .captionTitle {
        font-size: 1.35rem;
        font-weight: 500;
    }

    .captionBtn {
        background-color: #f5f5f5 !important;
        border: 1px solid #007bff !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        border-radius: 0.25rem
    }
</style>