<template>
  <div class="profile-nav mt-4">
    <div class="homeTabsWrapper profileNav">

<!--      <b-tabs>-->
<!--        <b-tab-->
<!--          v-for="nav in navBarData"-->
<!--          @click="activeTab(nav)"-->
<!--          :key="nav.id+'_'+Math.random()"-->
<!--          :title="nav.title"-->
<!--        >-->

          <b-card-text>
            <div v-if="componentPages === 1">
              <articlePost />
            </div>
            <div v-if="componentPages === 2">
              <headingRequest />
            </div>
          </b-card-text>
<!--        </b-tab>-->
<!--      </b-tabs>-->
    </div>
  </div>
</template>

<script>
import articlePost from "../../views/articlePost";
import headingRequest from "../../views/headingRequest";

export default {
  name: "ArticleNavbar",
  props: {
    navBarData: Array,
  },

  data() {
    return {
      componentPages: 1,
    };
  },
  methods: {
    activeTab(nav) {
      this.componentPages = nav.id;
    },
  },
  components: {
    articlePost,
    headingRequest,
  },
};
</script>
<style scoped>
.active{
  background-color:transparent!important;
}
</style>

