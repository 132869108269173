<template>
    <div class="hide">
        <div class="row">
            <div class="col-lg-8 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"  />
                <div class="">
                    <h1 class="firstHeading">{{articleDetail.title}}</h1>
                    <!-- <h2 class="secondHeading mb-2">{{articleDetail.subTitle}}</h2>-->
                </div>

                <!---Footer--->
                <div class="social-icon social-icon-blog d-flex mt-lg-3 mt-sm-0">
                <div class="view mb-0 w-25">{{articleDetail.total_views_skim}} Views</div>
<!--    <div class="badge-detail">-->
<!--                <table>-->
<!--                <tbody class="table-body">-->
<!--                <tr>-->
<!--                    <td>-->
<!--                    <label class="mr-1 mb-0 small-head supportive ml-5">-->
<!--                        <span class="badge"  style="background-color: #28a745; color: #fff">-->
<!--                        Supportive-->
<!--                        </span>-->
<!--                    </label>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                     <label class="pr-1 mb-0 small-head">-->
<!--                    <span class="badge"  style="color: #fff; background-color: #343a40">Neutral-->
<!--                      </span>-->
<!--                     </label>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                    <label class="pr-1 mb-0 small-head">-->
<!--                    <span class="badge"  style="color: #fff; background-color: #dc3545">-->
<!--                        Critique-->
<!--                    </span></label>-->
<!--                    </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td class="count"><label class="font-sd ml-5">{{articleDetail.total_supportive}}</label>-->
<!--                    </td>-->
<!--                    <td class="count"><label class="font-sd pr-1">{{articleDetail.total_neutral}}</label>-->
<!--                    </td>-->
<!--                    <td class="count"><label class="font-sd pr-1">{{articleDetail.total_critique}}</label>-->
<!--                    </td>-->
<!--                </tr>-->
<!--            </tbody>-->
<!--        </table>-->
<!--    </div>-->
                    <div class="progress w-50">
                        <div class="progress-bar bg-success" role="progressbar" :style="{'width':articleDetail.total_supportive_percentage+'%'}">
                            {{articleDetail.total_supportive_percentage}}%
                        </div>
                        <div class="progress-bar bg-dark" role="progressbar" :style="{'width':articleDetail.total_neutral_percentage+'%'}">
                            {{articleDetail.total_neutral_percentage}}%
                        </div>
                        <div class="progress-bar bg-danger" role="progressbar" :style="{'width':articleDetail.total_critique_percentage+'%'}">
                            {{articleDetail.total_critique_percentage}}%
                        </div>
                    </div>

                    <!-- <div class="socail-link w-25">
                        <table class="blog-info info-wrapper mb-2 w-100">
                            <tbody>
                            <tr>
                                <td class="w-73 pl-2 pr-2">
                                    <a href="https://theworded.com/testing/www/html/public/index.php/userprofile/9c67ce0e07f42c96be4d2b38c28bd1af">
                                        <label
                                                class="mb-0 mr-2 shareText"
                                                style="color: gray; font-size: 16px">
                                            Share:
                                        </label>
                                    </a>
                                    <a class="social-icon">
                                        <i
                                                class="fa-brands fa-facebook-f"
                                                style="
                          color: gray;
                          width: 17px;
                          font-size: 15px;
                          text-align: center;
                        "
                                        >
                                        </i>
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=" target="_blank"
                                       class="social-icon">
                                        <i
                                                class="fa-brands fa-twitter"
                                                style="
                          color: gray;
                          width: 20px;
                          font-size: 15px;
                          text-align: center;">
                                        </i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
            <!---image---->
            <div class="col-lg-12 col-sm-12 col-12">
            <VideoOrImageFrame
                    :videoUrl="articleDetail.video_url"
                    :imageUrl="articleDetail.featured_image"
                    imageClass="default-view-image"
                    mainClass="oversize-image box-image"
            />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"


    export default {
        name: "OverSized",
        components: {userProfileDetail, VideoOrImageFrame},
        props: {
            articleDetail: Object,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>
<style scoped>
.firstHeading{
   margin-top: 7px;
    overflow-wrap: break-word;
    font-size: 32px;
    max-height: 140px;
    font-family: "Merriweather";
    font-weight: 400;
    list-style: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.social-icon-blog{
    justify-content: space-between;
}
</style>
