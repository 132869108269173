<template>
    <div class="row">
        <div class="col-lg-12">
            <div
                    v-for="comment in getAllCommentsSend"
                    :key="comment.id+'_'+Math.random()"
            >
                <userSendComment :comment="comment"/>
            </div>
            <infinite-loading
                    spinner="spiral"
                    @infinite="retrievedAllCommentsSend"
            ></infinite-loading>
        </div>
    </div>

</template>
<script>
    import InfiniteLoading from "vue-infinite-loading";
    import userSendComment from '../UserComments/UserSendComment'
    import {mapGetters} from "vuex";

    export default {
        components: {
            userSendComment,
            InfiniteLoading
        },
        data() {
            return {}
        },
        computed:{
            ...mapGetters({
                getAllCommentsSend: "article/getAllCommentsSend",
                getAllCommentsSendPage: "article/getAllCommentsSendPage",
            }),
        },
        methods: {
            retrievedAllCommentsSend($state) {
                let payload = {
                    page: this.getAllCommentsSendPage,
                    $state: $state,
                };
                this.$store.dispatch("article/getAllCommentsSend", payload);
            },
        },
        mounted() {
            this.retrievedAllCommentsSend();
        }

    }

</script>
<style scoped="">

</style>