<template>
    <div>
        Login
    </div>
</template>
<script>
    export default {
        data(){
            return {}
        },
        methods:{
            testingUserLogin(){
                let payload = {
                    email: 'arslan@gmail.com',
                    name: 'Arslan',
                    avatar: 'http://theworded.mashghol.com/img/no-image.9ce6cc12.png',
                    token: 'sjzoidawieiqwoafhaidhcawiodhqwfihaweduasijhfddziu',
                    social_id: 'sjzoidawieiqwoafhaidhcawiodhqwfihaweduasijhfddziu',
                    social_type: 'google',
                }
                this.$store.dispatch('auth/login', payload).then(()=>{
                    window.location = '/home';
                })
            }
        },
        mounted() {
            this.testingUserLogin();
        }
    }

</script>