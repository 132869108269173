<template>
    <div class="select">
        <div class="container mt-2">
            <div class="row">
                <div class="col-sm-12">
                    <div class="wrap-icon">
                        <a v-if="customSelectedFlag.flag === 'Select Country'" class="country-flag">
                            {{ customSelectedFlag.flag }}
                        </a>
                        <a v-else class="country-flag">
                            <img class="icon" alt="flag" :src="customSelectedFlag.flag"/>
                        </a>
                        <div class="position-relative">
                            <Select2
                                    id="customCountryHomePage"
                                    v-model="myValue"
                                    :options="countryCustomOptions"
                                    @change="myChangeEvents($event)"
                                    @select="mySelectEvents($event)"/>
                        </div>
                    </div>
                </div>
                <!----select 2------>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Select2 from  'v-select2-component';


    export default {
        name: "SelectCountry",
        props: {
            isCustomHomePage: Boolean
        },
        components:{
            Select2,
        },
        data() {
            return {
                isLoginIn: true,
                myValue: "",
            };
        },
        computed: {
            ...mapGetters({
                countryCustomOptions: "country/countryCustomOptions",
                customSelectedFlag: "country/customSelectedFlag",
                defaultCustomCountry: "country/defaultCustomCountry",
            }),
        },
        methods: {
            myChangeEvents(val) {
                console.log(val);
            },
            mySelectEvents({id, text}) {
                let customCountry = {id: id, text: text};
                localStorage.setItem("customCountry", JSON.stringify(customCountry));
                this.getCustomSelectedFlags(id);
            },
            getCountries() {
                this.$store.dispatch("country/getCountriesForCustom");
            },
            getCustomSelectedFlags(id) {
                this.$store.dispatch("country/customSelectedFlag", id);
            },
            selectedCountry(id) {
                this.$store.dispatch("country/getSelectedCountryForCustom", id);
            },
            testUserLogin() {
                console.log("testing testing");
                window.location = '/custom/homepage'
            }
        },
        mounted() {
            this.getCountries();
            this.selectedCountry(this.defaultCustomCountry.id)
        },
        watch: {
            myValue() {
                this.getCustomSelectedFlags(this.myValue)
                this.selectedCountry(this.myValue)
                this.$emit('updateCountyId', this.myValue)
            },
        },
    };
</script>

<style scoped>
    .wrap-icon {
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon {
        max-width: 22px;
        width: 100%;
        display: inline-block;
        cursor: pointer;
        margin: 0px 20px 0px 25px;
    }

    .country-flag {
        cursor: pointer;
        font-size: 14px;
        padding-inline: 2px;
        margin-left: 0px;
        color: #ccc !important;
        text-decoration: none;
    }

    #btn {
        border: none;
    }

    #icon-img {
        filter: grayscale(100%);
        width: 24px;
    }

    .plus-btn {
        border-radius: 50%;
        font-size: 15px;
        width: 24px;
        height: 24px;
        background-color: #d4d5d6;
        border: 1px solid #d4d5d6;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
</style>
