<template>
    <div id="blog">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="box-image"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"/>
                <div class="blog-heading default-blog-heading">
                    <h1 class="first-heading">{{ articleDetail.title }}</h1>
                </div>

                <!---Footer--->
                <div class="social-icon social-icon-blog d-flex mt-lg-2  mt-md-4 mt-sm-4">
                    <div class="view mb-0 ">{{articleDetail.total_views_skim}} Views</div>
<!--                    <div class="badge-detail">-->
<!--                        <table>-->
<!--                            <tbody class="table-body">-->
<!--                            <tr class="p-0">-->
<!--                                <td>-->
<!--                                    <label class="mr-1 mb-0 small-head supportive ml-5">-->
<!--                                        <span class="badge"  style="background-color: #28a745; color: #fff">Supportive</span>-->
<!--                                    </label>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!--                                        <span class="badge"  style="color: #fff; background-color: #343a40">Neutral</span>-->
<!--                                    </label>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!--                                        <span class="badge"  style="color: #fff; background-color: #dc3545">Critique</span>-->
<!--                                    </label>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="count"><label class="font-sd ml-5">{{articleDetail.total_supportive}}</label>-->
<!--                                </td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_neutral}}</label>-->
<!--                                </td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_critique}}</label>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->

                    <div class="progress w-50">
                        <div class="progress-bar bg-success" role="progressbar" :style="{'width':articleDetail.total_supportive_percentage+'%'}">
                            {{articleDetail.total_supportive_percentage}}%
                        </div>
                        <div class="progress-bar bg-dark" role="progressbar" :style="{'width':articleDetail.total_neutral_percentage+'%'}">
                            {{articleDetail.total_neutral_percentage}}%
                        </div>
                        <div class="progress-bar bg-danger" role="progressbar" :style="{'width':articleDetail.total_critique_percentage+'%'}">
                            {{articleDetail.total_critique_percentage}}%
                        </div>
                    </div>

                    <!-- <div class="social-link ">
                        <table class="blog-info info-wrapper mb-2 w-100">
                            <tbody>
                            <tr>
                                <td class="">
                                    <a href="https://theworded.com/testing/www/html/public/index.php/userprofile/9c67ce0e07f42c96be4d2b38c28bd1af">
                                        <label class="mb-0 shareText" style="color: gray; font-size: 16px">Share:</label>
                                    </a>
                                    <a @click="shareArticle(articleDetail)" class="social-icon" target="_blank">
                                        <i class="fa-brands fa-facebook-f" style="color: gray;width: 17px;font-size: 15px;text-align: center;"></i>
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=" target="_blank" class="social-icon">
                                        <i class="fa-brands fa-twitter" style="color: gray;width: 20px;font-size: 15px;text-align: center;"></i>
                                    </a> 
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";

    export default {
        name: "BlogPage",
        components: {
            userProfileDetail,
            VideoOrImageFrame
        },
        props: {
            articleDetail: Object,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            shareArticle(article) {
                let url = 'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Ftheworded.com%2F&details/';
                console.log("testing test", article);
                window.location.href = url + article.slug;
                window.open('href', '_blank');
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },

        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
            isValidateButton() {
                return this.type === '' && this.commentTag === "" && this.comment === ""
            }
        },
    };
</script>

<style scoped>

</style>
