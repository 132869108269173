<template>
    <div class="select">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 col-xxl-8 mx-auto">
                <div class="wrap-icon">
                    <ParentCategories v-if="isLogin" @emitUpdateFavouriteCategory="emitUpdateFavouriteCategory"
                                      @emitActiveCategory="emitActiveCategory"/>
                    <a href="javascript:void(0)" v-if="isLogin && isCustom === 'customHomePage' " type="button"
                       class="btn-primary btn-sm plus-btn shadow-none d-inline-flex align-items-center justify-content-center"
                       data-toggle="modal" data-target="#parentModal">+</a>
                    <router-link to="/custom/homepage" v-if="isLogin" class="d-inline-block">
                        <img :src="getAuthUser.profile_pic" class="rounded-circle" id="icon-img" alt="icon"/>
                    </router-link>
                    <router-link v-if="selectedFlag.flag === 'Select Country'" to="/home" class="country-flag">{{
                        selectedFlag.flag }}
                    </router-link>

                    <img @click="globeCountry" id="globe" class="icon" alt="flag" src="../../assets/images/globe.png"/>
                    <div class="d-flex">
                        <img @click="mySelectEvent({id:selectedFlag.id, text:selectedFlag.name})" class="icon "
                             id="country" alt="flag" :src="selectedFlag.flag"/>
                        <div class="position-relative">
                            <Select2
                                    id="customCountry"
                                    :options="countryOptions"
                                    @change="myChangeEvent($event)"
                                    @select="mySelectEvent($event)"
                            />
                        </div>
                    </div>

                </div>
            </div>

            <!----select 2------>
        </div>

    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    import ParentCategories from '../../components/Modals/ParentCategories';
    import $ from 'jquery';
    import Select2 from 'v-select2-component';


    export default {
        name: "SelectCountry",
        props: {
            isLogin: Boolean,
            isCustom: String
        },
        data() {
            return {
                isLoginIn: true,
            };
        },
        components: {ParentCategories, Select2},
        computed: {
            ...mapGetters({
                countryOptions: "country/countryOptions",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",
                getAuthUser: "auth/getAuthUser",
            }),
        },
        methods: {
            myChangeEvent(val) {
                console.log(val);
            },
            changeGrayScale(id) {
                if (id === 1) {
                    $("#globe").removeClass('icon-grayscale');
                    $("#country").addClass('icon-grayscale');
                    $("#icon-img").addClass('icon-grayscale');
                } else {
                    $("#globe").addClass('icon-grayscale');
                    $("#country").removeClass('icon-grayscale');
                }
            },
            mySelectEvent({id, text}) {

                this.changeGrayScale(id);
                let country = {id: id, text: text};
                localStorage.setItem("country", JSON.stringify(country));
                if (id !== 1) {
                    localStorage.setItem("selectedFlagCountryId", JSON.stringify(id));
                    this.getSelectedFlag(id)
                }

                this.selectedCountry(id)
                this.$emit('updateCountyId', id)
                if (this.isCustom === 'customHomePage') {
                    window.location.href = '/home';
                }
            },
            globeCountry() {
                this.mySelectEvent({id: 1, text: "world"})
            },
            getCountries() {
                this.$store.dispatch("country/getCountries");
            },
            getSelectedFlag(id) {
                this.$store.dispatch("country/selectedFlag", id);
            },
            selectedCountry(id) {
                this.$store.dispatch("country/getSelectedCountry", id);
            },
            emitActiveCategory(category) {
                this.$emit("emitActiveCategory", category)
            },
            emitUpdateFavouriteCategory(bool) {
                if (bool) this.$emit('updateCountyId', this.defaultCountry.id)
            },
            updateIsCustom() {
                if (this.isCustom === 'customHomePage') {
                    $("#globe").addClass('icon-grayscale');
                    $("#country").addClass('icon-grayscale');
                    $("#icon-img").removeClass('icon-grayscale');
                }
            }

        },
        mounted() {
            this.getCountries();
            let flagId = localStorage.getItem("selectedFlagCountryId");
            if (!flagId) {
                localStorage.setItem("selectedFlagCountryId", "1");
                flagId = localStorage.getItem("selectedFlagCountryId");
            }
            this.selectedCountry(this.defaultCountry.id)
            this.getSelectedFlag(JSON.parse(flagId));
            this.changeGrayScale(this.defaultCountry.id);
            this.updateIsCustom()
        },
        watch: {},
    };
</script>

<style scoped>
    .wrap-icon {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .icon {
        max-width: 22px;
        width: 100%;
        display: inline-block;
        cursor: pointer;
        /*margin: 0px 1px 0px 25px;*/
    }

    .country-flag {
        cursor: pointer;
        font-size: 14px;
        padding-inline: 2px;
        margin-left: 0px;
        color: #ccc !important;
        text-decoration: none;
    }

    #btn {
        border: none;
    }

    #icon-img {
        /*filter: grayscale(100%);*/
        width: 24px;
        height: 24px;
    }

    .plus-btn {
        border-radius: 50%;
        font-size: 15px;
        width: 24px;
        height: 24px;
        background-color: #d4d5d6;
        border: 1px solid #d4d5d6;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .icon-grayscale {
        filter: grayscale(100%);
    }

</style>
