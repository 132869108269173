<template>
    <div id="profile">

        <div class="row mt-1">
            <div class="col-sm-12 col-12">
            <div  id="profile-cover">
              
                <img :src="getAuthUser.sec_1" alt="section-one" width="100%" height="250" style="object-fit: cover">
            </div>
            </div>
        </div>

        <div id="profile-img" class="row  mt-3 mb-3">
            <div class="col-sm-12 profile-img">
                <div id="user-img" class=" d-inline ">
                    <img :src="getAuthUser.profile_pic" width="60" height="60" class="rounded-circle mr-3" alt="profile-picture">
                </div>
                <div class="user-name d-inline">
                    <table class="d-inline user-profile-table">
                        <tr>
                            <td>
                                <h3 class="d-inline">{{getAuthUser.name}}</h3></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="subscriber">
                                <span class="badge badge-pill badge-info"
                                      style="color: #fff;background-color: #17a2b8;"><i>Subscribers: {{getAuthUser.total_user_subscribers}} </i></span>
                                    <a v-bind:href="getAuthUser.socail_user.fb_url" target="_blank" class="social-icon">
                                        <i class="fa-brands fa-facebook-f icon"></i>
                                    </a>
                                    <a v-bind:href="getAuthUser.socail_user.twitter_url" target="_blank"
                                       class="social-icon">
                                        <i class="fa-brands fa-twitter icon"></i>
                                    </a>
                                    <a v-bind:href="getAuthUser.socail_user.youtube_url" target="_blank"
                                       class="social-icon">
                                        <i class="fa-brands fa-youtube fa-fw icon"></i>
                                    </a>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 profile-text">
                <h6 class="d-inline-block pt-3">{{getAuthUser.description}}</h6>
            </div>

        </div>
        <published/>

    </div>
</template>

<script>
    import published from '../views/published'
    import {mapGetters} from "vuex";

    export default {
        name: 'Profile',
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Profile'
        },
        components: {published},
        data() {
            return {
                navBarArray: [{id: 1, title: "settings"}],
            };
        },
        computed: {
            ...mapGetters({
                getAuthUser: 'auth/getAuthUser'
            })
        },
        methods: {
            updateUserSettings() {
            }

        },
        mounted() {
        }
    }

</script>

<style scoped>
    #profile-cover {
        height: 250px;
        width: 100%;
    }

#profile-img{
text-align: center;
}
.user-profile-table{
    text-align: initial;
}
    .user-name h3 {
        font-size: 25px;
    }

    .social-icon .icon {
        color: gray;
        width: 23px;
        font-size: 14px;
        text-align: center;
    }

    .profile-text h6 {
        font-size: 0.9rem;
    }

</style>