<template>
    <div class="subsciber">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <div class="text text-center mr-xxl-0   mr-lg-0 mr-sm-5 mr-xxl-0 mb-xxl-0 mb-lg-0 mb-sm-3 mb-3">
                        <a v-if="isLogin" href="javascript:void(0)" data-toggle="modal" data-target="#subscribe-category-modal" id="click-icon"><i class="fa-regular fa-plus"></i></a>
                        <span class="first-span">{{subscriber }}</span>
                        <br /><span class="second-span"> {{total}}</span>
                    </div>
                </div>
            </div>
            <SubscribeCategoryModal :category="category" />

        </div>
    </div>

</template>

<script>
    import SubscribeCategoryModal from "../Modals/SubscribeCategoryModal";
    export default {
        name: 'Subscriber',
        components:{
            SubscribeCategoryModal
        },
        props: ['category','subscriber', 'total','isLogin'],
        data() {
            return {}
        },

    }
</script>

<style scoped>
#click-icon{
   display:inline-block;
   position: relative;
   top:-6px;
}
    #click-icon .fa-plus {
        border-radius: 50%;
        font-size: 15px;
        width: 24px;
        height: 24px;
        background-color: #d4d5d6;
        color: white;
        line-height: 1.5;
        border: 1px solid #d4d5d6;
        padding: 0;
        font-weight: 500;
        margin-left: -15px;
        margin-right: 4px;
    }

    .first-span {
        font-size:30px;
        color: black;
        margin: 10px 5px;
    }
    .second-span{
        font-size: 15px;
    }
</style>