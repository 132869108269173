<template>
    <div id="post pt-5">
        <div class="row main">
            <div class="col-8  m-auto p-0">
                <div class="mb-md-0  w-100 d-flex justify-content-end">
                    <span v-if="isSaving"><i class="far fa-save mt-2 mt-md-2 mt-sm-2"> </i> saving... </span>
                    <button @click="checkIsSkim" :disabled="isDisabled" class="btn mb-3 publish-button">
                        {{isUpdate ? 'Update': 'Publish' }}
                    </button>
                </div>
                <div class="row second-row m-0 " style="clear: both">
                    <div class="col-8 col-lg-8 col-md-8 col-sm-8">
                        <form class=" mb-2 mt-md-0 mt-sm-0 pt-2">
                            <div class="title-input form-group  mb-2">
                                <input v-model="title" @keyup="submitArticleApi = true" type="text" placeholder="Title"
                                       class="input"/>
                            </div>
                            <br class="d-lg-block d-sm-none"/>
                            <!--                            <div class="title-input form-group mb-2">-->

                            <!--                                 <input v-model="subTitle" @keyup="submitArticleApi = true" type="text"-->
                            <!--                                       placeholder="Subtitle" class="input"/>-->
                            <!--                            </div>-->
                            <!--                            <br/>-->
                            <div class="form-group mb-3 mb-md-0 mb-sm-1" style="transition: 0.5s">
                                <input v-model="youtube" @keyup="submitArticleApi = true" type="text"
                                       placeholder="Add Youtube Video Link here"
                                       class="input"
                                       style="border-bottom: 1px solid gray"/>
                                <span class="text-danger">{{message}}</span>
                            </div>
                        </form>
                        <div class="mt-5">
                            <button type="button" data-toggle="modal" data-target="#exampleModalLong"
                                    id="customize_btn">
                                <i class="fa-solid fa-pen-to-square"></i>
                                <a class="font-weight-bold mt-2">Customize</a>
                            </button>
                        </div>
                    </div>
                    <div class="col-4 col-lg-4 col-md-4 col-sm-4 pl-4">
                        <iframe v-if="youtube" width="230px" height="160px"
                                :src="updateYoutube(youtube)">
                        </iframe>
                        <img v-else :src="headerImage(featured_img)" width="230px" height="160px"
                             id="top-img"/>
                        <div class="image-upload">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#headerImageModal">
                                <img src="../assets/images/no-img.png" class="small-img mt-lg-2 mt-md-2" width="30px"
                                     height="25px"/>
                            </a>
                            <img v-on:click="show = !show" class="small-img mt-lg-2 mt-md-2 pl-1 "
                                 src="../assets/images/youtube-logo.png" alt="Youtube link" width="0px" height="0px"
                                 style="cursor: pointer"/>
                        </div>
                    </div>

                    <div class=" col-sm-12  p-0 mt-2">
                        <div>
                            <summernote
                                    :config="summernoteConfig"
                                    @ready="readySummernote"
                            ></summernote>
                            <div class="col-xs-12 text-right characters">

                                (Min: {{contents.length}} Max: 40000)
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <CaptionModal @emitImageCaption="emitImageCaption"/>
        <TweetModal @emitTweetLink="emitTweetLink"/>
        <CustomizationModal @emitCustomization="emitCustomization"
                            emitCustomizationHeaderImage="emitCustomizationHeaderImage"/>
        <HeaderImageModal v-if="this.getArticleId() !== ''"
                          @emitHeaderImage="emitHeaderImage"
                          @emitHeaderGif="emitHeaderGif"

                          @emitHeaderImagePreview="emitHeaderImagePreview"/>
        <PublishModal :isSubmit="isSubmit" :isUpdate="isUpdate"
                      @emitSelectWorldCategories="emitSelectWorldCategories"
                      @emitSelectWorldHashTag="emitSelectWorldHashTag"
                      @emitSelectCategories="emitSelectCategories"
                      @emitSelectHashTag="emitSelectHashTag"
                      @emitSubmitPublishModal="emitSubmitPublishModal"

        />
    </div>
</template>

<script>

    import CustomizationModal from "../components/Modals/CustomizationModal";
    import PublishModal from "../components/Modals/PublishModal";
    import HeaderImageModal from "../components/Modals/HeaderImageModal";
    import TweetModal from "../components/Modals/TweetModal";
    import Summernote from "./Summernote";
    import CaptionModal from "../components/Modals/captionModal";
    import Helper from '../helper';
    import {mapGetters} from "vuex";

    import $ from "jquery";

    export default {
        name: "articlePost",
        components: {

            CustomizationModal,
            PublishModal,
            Summernote,
            HeaderImageModal,
            TweetModal,
            CaptionModal,
        },
        data() {

            return {
                scrollPosition: null,
                show: false,
                isSaving: false,
                polling: null,
                isSubmit: false,
                message: '',
                summernoteConfig: {
                    tabsize: 2,
                    height: 500,
                    toolbar: [
                        ['mybutton', ['h1', 'h2', 'plain']],
                        ['h1', 'h2'],
                        ['style', ['bold', 'italic', 'underline']],
                        ['para', ['ul', 'ol']],
                        ['insert', ['link', 'picture', 'video', 'hr']],
                        ['mybutton', ['quote']],
                        ['mybutton', ['gif']],
                        ['mybutton', ['tweet']],
                        ['mybutton', ['skim']],
                    ],
                    popover: {
                        image: [
                            ["image", ["resizeQuarter", "resizeHalf", "resizeHalf1", "resizeFull"]],
                            ["float", ["floatLeft", "floatRight", "floatNone"]],
                            ["remove", ["removeMedia", "imageCaption"]],
                        ],
                        link: [["link", ["linkDialogShow", "unlink"]]],
                        air: [
                            ["color", ["color"]],
                            ["font", ["bold", "underline", "clear"]],
                            ["insert", ["link", "picture"]],
                        ],
                    },
                },
                contents: "",
                title: "",
                // subTitle: "",
                youtube: "",
                customize: {
                    layout: "default",
                    customizeTitle: "default",
                },
                description: "",
                upcoming_content: "",
                globe_categories: [],
                globe_hashtags: [],
                status_id: 5,
                type: "",
                article_id: "",
                featured_img: "",
                monetize: "",
                category: "",
                subcategory: "",
                country_categories: [],
                country_hashtags: [],
                country_id: "",
                customize_article: "",
                gif_img: "",
                video_url: "",
                featured_gif_img: "",
                featured_image: "",
                mobile_thumbnail: "",
                isUpdate: false,
                submitArticleApi: false,
                isSkim: false
            };
        },
        computed: {
            ...mapGetters({
                articleDetail: "article/getArticleDetails",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",
            }),
            isDisabled() {
                return this.checkKeys()
            },

        },
        methods: {
            updateYoutube(youtube) {
                let youtubeVideoId = this.getYoutubeVideoId(youtube);
                if (!youtubeVideoId) {
                    this.message = 'Please enter the valid youtube video url';
                    return;
                }
                return 'https://www.youtube.com/embed/' + youtubeVideoId;
            },
            getYoutubeVideoId(youtube) {
                var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                if (youtube.match(p)) {
                    return youtube.match(p)[1];
                }
                return false
            },
            updateScroll() {
                this.scrollPosition = window.scrollY
                if (this.scrollPosition > 300) {
                    $(".note-toolbar").addClass('classA')
                } else if (this.scrollPosition < 300) {
                    $(".note-toolbar").removeClass('classA')
                }
            },
            readySummernote(editor) {
                editor.summernote("code", this.contents);
                editor.$on("change", (contents) => {
                    this.submitArticleApi = true;
                    console.log('this.submitArticleApi', this.submitArticleApi)
                    this.contents = contents;
                    console.log("testing skim", this.checkSkim(this.contents));
                });
            },
            emitCustomization(customize) {
                this.customize.layout = customize.layout
                this.customize.customizeTitle = customize.customizeTitle
                this.submitArticleApi = true;
            },
            emitSelectWorldCategories(selectWorldCategories) {
                this.globe_categories = [];
                let selectedCategories = [];
                selectWorldCategories.map(x => {
                    selectedCategories.push({id: x.code, name: x.name})
                })
                this.globe_categories = selectedCategories
                console.log("this.globe_categories", selectedCategories);
                this.submitArticleApi = true;
            },
            emitSelectWorldHashTag(selectWorldHashTag) {
                this.globe_hashtags = [];
                selectWorldHashTag.map(x => {
                    this.globe_hashtags.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSelectCategories(selectCategories) {
                this.country_categories = [];
                selectCategories.map(x => {
                    this.country_categories.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSelectHashTag(selectHashTag) {
                this.country_hashtags = [];
                selectHashTag.map(x => {
                    this.country_hashtags.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSubmitPublishModal(isSubmit) {
                if (isSubmit) {
                    this.isSubmit = true;
                    this.status_id = 7
                    this.submitArticleApi = true;
                }
            },
            emitHeaderImagePreview(file) {
                this.featured_img = file
            },
            emitHeaderImage(file) {
                this.featured_image = file
                this.submitArticleApi = true;
            },
            emitCustomizationHeaderImage(file) {
                this.featured_image = file
                console.log("file",file)
                this.submitArticleApi = true;
            },
            emitHeaderGif(gif) {
                this.featured_img = gif
                this.gif_img = gif
                this.submitArticleApi = true;
            },
            headerImage(image) {
                return image.length !== 0 ? image : '/no-image-log.png';
            },
            emitTweetLink(tweet) {
                $('.note-editable').append(tweet)
            },
            checkKeys() {
                return this.title === "";
            },
            checkSkim(contents) {
                console.log("checking contents", contents);
                let position = contents.search('&lt;SKIM&gt;');
                let position2 = contents.search('&lt;/SKIM&gt;');
                console.log("checking position", position, position2);
                let total = position2 - position;
                this.isSkim = total > 12
            },
            getArticleId() {
                if (this.articleDetail) {
                    if (this.articleDetail.id !== 'undefined') {
                        return this.articleDetail.id
                    }
                    return ''
                }
                return ''
            },
            addArticle() {
                this.isSaving = true;
                let formData = new FormData();
                formData.append('title', this.title)
                // formData.append('sub_title', this.subTitle)

                formData.append('layout', this.customize.layout)
                formData.append('customize_title', this.customize.customizeTitle)
                formData.append('content', this.contents)
                formData.append('description', this.description)
                formData.append('upcoming_content', this.upcoming_content)
                this.globe_categories.forEach((x, i) => {
                    formData.append(`globe_categories[${i}][id]`, x.id)
                    formData.append(`globe_categories[${i}][name]`, x.name)
                })
                this.globe_hashtags.forEach((x, i) => {
                    formData.append(`globe_hashtags[${i}][id]`, x.id)
                    formData.append(`globe_hashtags[${i}][name]`, x.name)
                })
                this.country_categories.forEach((x, i) => {
                    formData.append(`country_categories[${i}][id]`, x.id)
                    formData.append(`country_categories[${i}][name]`, x.name)
                })
                this.country_hashtags.forEach((x, i) => {
                    formData.append(`country_hashtags[${i}][id]`, x.id)
                    formData.append(`country_hashtags[${i}][name]`, x.name)
                })
                formData.append('status_id', this.status_id)
                formData.append('type', this.type)
                let cardData = localStorage.getItem('cardData');
                if (cardData) {
                    let data = JSON.parse(cardData);
                    formData.append('article_id', data.id)
                } else {
                    formData.append('article_id', this.getArticleId())
                }
                formData.append('is_monetize', this.monetize)
                formData.append('category', this.category)
                formData.append('subcategory', this.subcategory)
                formData.append('country_id', this.defaultCountry.id)
                formData.append('customize_article', this.customize_article)
                formData.append('gif_img', this.gif_img)
                formData.append('video_url', this.youtube)
                formData.append('featured_gif_img', this.featured_gif_img)
                formData.append('featured_image', this.featured_image)
                formData.append('mobile_thumbnail', this.mobile_thumbnail)
                console.log("data qqqq", formData);
                if (!this.checkKeys() && this.submitArticleApi) {
                    this.$store.dispatch('article/createArticle', formData).then(response => {
                        localStorage.setItem('cardData', JSON.stringify(response.data))
                        this.submitArticleApi = false
                        this.updateIsSaving();
                        if (this.isSubmit && response.data.status.id === 7) {
                            this.closeModal('publishModal')
                            localStorage.removeItem('cardData');
                            localStorage.removeItem('isEdit');
                            this.$router.push({name: 'Details', params: {slug: response.data.slug}})
                        }
                    }).catch(error => {
                        console.log('error=>', error);
                    })
                } else {
                    this.updateIsSaving();
                    this.submitArticleApi = false
                }

            },
            emitImageCaption(payload) {
                if (this.getArticleId()) {
                    Object.assign(payload, {article_id: this.getArticleId()})
                    this.closeModal('captionModal');
                    this.$store.dispatch('article/imageCaption', payload);
                }
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            updateIsSaving() {
                let vm = this;
                setInterval(function () {
                    vm.isSaving = false;
                }, 1000)
            },
            editArticle() {
                let cardData = localStorage.getItem('cardData');
                if (cardData) {
                    if (localStorage.getItem('isEdit')) {
                        this.isUpdate = true
                    }
                    let data = JSON.parse(cardData);
                    console.log("data", data)
                    this.contents = data.content;
                    this.title = data.title;
                    // this.subTitle = data.sub_title;
                    this.youtube = data.video_url;
                    this.customize.layout = data.layout;
                    this.customize.customizeTitle = data.customize_title;
                    this.description = data.description;
                    this.upcoming_content = data.upcoming_content;
                    this.status_id = data.status.id;
                    this.monetize = data.is_monetize;
                }
            },
            checkIsSkim() {
                if (this.contents.length < 1000) {
                    Helper.toast(this, 'Alert', 'Minimum characters are 1000 for article. Please check it', 'danger')
                    // this.toast('b-toaster-top-center');
                    return
                }
                if (!this.isSkim) {
                    Helper.toast(this, 'Alert', 'Please enter the Skim', 'danger')
                    // this.toast('b-toaster-top-center');
                    return
                }
                $('#publishModal').modal('show');
            }
        },
        watch: {
            submitArticleApi() {
                this.addArticle();
            },
            youtube(val) {
                if (!val) this.message = ""
            }
        },
        mounted() {
            this.$store.dispatch('article/emptyArticleDetail')
            window.addEventListener('scroll', this.updateScroll);
            this.editArticle();

        },
    };
</script>

<style scoped>
    .main {
        margin-top: 53px;

    }

    .publish-button {
        background: none;
        border: 2px solid black;
        border-bottom-width: 4px;
        font: inherit;
        height: 40px;
        letter-spacing: inherit;
        padding: 0 27px;
        border-radius: 0;
        text-transform: inherit;
        -webkit-transition: color 1s;
        transition: color 1s;
        text-align: center;
        font-size: 14px;
    }

    .publish-button:hover {
        color: #006692;
        -webkit-animation: zigzag 1s linear infinite;
        animation: zigzag 1s linear infinite;
        background: linear-gradient(135deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(225deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(315deg, #dee2e67d 0.25em, transparent 0.25em) 0 0,
        linear-gradient(45deg, #dee2e67d 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.75em;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        cursor: pointer;
    }

    #customize_btn {
        background-color: transparent;
        border: none;
    }

    .input {
        background: transparent;
        outline: none;
        border: none;
        border-radius: 5px;
        border-bottom: 1px solid #dee2e6;
        width: 100%;
        height: 37px;
        padding: 0.375px 12px;
    }

    .input::placeholder {
        color: #6d757d !important;
        font-size: 14px;
    }

    .customize i,
    a {
        font-size: 13px;
        color: #6d757d !important;
    }

    .button {
        color: green;
        font-size: 14px;
    }

    blockquote {
        border-left: 4px solid #ccc;
        padding-left: 10px;
        font-size: 21px;
    }

    .characters {
        margin-bottom: 160px;
        font-size: 14px;
    }

</style>
