import axios from "../../axios.js";

export default {
    async getArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/country/' + payload.countryId + '?type=' + payload.type, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getCategoryArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type:payload.type
                },
            }
            axios.get('/article/country/' + payload.countryId + '/category/' + payload.categoryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_CATEGORY_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async setPageNumber({commit}, page) {
        commit('SET_PAGE_NUMBER', page)
    },
    async setCategoryPageNumber({commit}, page) {
        commit('SET_CATEGORY_PAGE_NUMBER', page)
    },

    async setHashTagData({commit}, data) {
        commit('SET_HASH_TAG_DATA', data)
    },
    async updatedPaginateArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/country/' + payload.countryId+'/category/' + payload.categoryId, object).then((response) => {
                commit('UPDATED_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getBreakingAndUpComingArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/breaking/upcoming/' + payload.id + '?type=' + payload.type).then((response) => {
                commit('GET_BREAKING_AND_UP_COMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getCategoryBreakingAndUpComingArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/breaking/upcoming/' + payload.countryId + '/category/' + payload.categoryId+'?type=' + payload.type).then((response) => {
                commit('GET_CATEGORY_BREAKING_AND_UP_COMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async articleDetail({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/detail/' + payload.slug).then((response) => {
                commit('ARTICLE_DETAIL', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async createArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article', payload).then((response) => {
                commit('ARTICLE_DETAIL', response)
                commit('ADD_DRAFT_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async emptyArticleDetail({commit}) {
        commit('EMPTY_ARTICLE_DETAIL')
    },
    async uploadEditorImage({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/upload_editor_image', payload).then((response) => {
                commit('UPLOAD_EDITOR_IMAGE')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async imageCaption({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/image_caption', payload).then((response) => {
                commit('IMAGE_CAPTION', payload)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getDraftAndUpcoming({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/user/draft/and/upcoming', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_DRAFT_AND_UPCOMING', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getPublishedArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/user/published/articles', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_PUBLISHED_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllBreakingArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type:payload.type
                },
            }
            axios.get('/article/breaking/country/'+payload.countryId+'/category/'+payload.categoryId, object).then((response) => {
            // axios.get('/article/breaking/' + payload.countryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_BREAKING_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllUpcomingArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/upcoming/' + payload.countryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_UPCOMING_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async addComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/comment', payload).then((response) => {
                commit('ADD_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async addLikeOrDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/comment/like', payload).then((response) => {
                commit('ADD_LIKE_OR_DISLIKE_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async deleteLikeOrDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete('/article/comment/like', {params: payload}).then((response) => {
                commit('ADD_LIKE_OR_DISLIKE_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllCommentsSend({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/comments/all/send', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_COMMENTS_SEND', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllCommentsReceived({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/comments/all/received', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_COMMENTS_RECEIVED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getWriterReplyCommentsSend({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/comments/writer/send').then((response) => {
                commit('GET_WRITER_REPLY_COMMENTS_SEND', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getWriterReplyCommentsReceived({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/comments/writer/received').then((response) => {
                commit('GET_WRITER_REPLY_COMMENTS_RECEIVED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getArticleSearch({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/search/' + payload.search).then((response) => {
                commit('GET_ARTICLE_SEARCH', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getSearchedArticleAndUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/searched/' + payload.search).then((response) => {
                commit('GET_ARTICLE_SEARCHED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async emptyArticleSearch({commit}) {
        commit('EMPTY_ARTICLE_SEARCH')
    },
    async getAllHashtagArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/hashtag/' + payload.slug + '/country/' + payload.countryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_HASHTAG_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getArticleDetailComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/detail/comments/' + payload.articleId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ARTICLE_DETAIL_COMMENTS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async updateUserSubscriber({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/subscriber', payload).then((response) => {
                if (payload.type === 'articleDetail') {
                    commit('UPDATE_ARTICLE_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'searchUser') {
                    commit('UPDATE_SEARCH_USER_SUBSCRIBER', response)
                }
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async archiveArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/article/archive/${payload.slug}`).then((response) => {
                commit('ARCHIVE_PUBLISH_ARTICLE', response)
                commit('ARCHIVE_DRAFT_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async skimLikeDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/rating`,payload).then((response) => {
                commit('SKIM_LIKE_DISLIKE_ARTICLE', response)
                commit('SKIM_LIKE_DISLIKE_BREAKING_ARTICLE', response)
                commit('SKIM_LIKE_DISLIKE_UPCOMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async addArticleViews({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/views`,payload).then((response) => {
                commit('ADD_ARTICLE_VIEWS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async addArticleSkimCount({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/skim/count`,payload).then((response) => {
                commit('ADD_ARTICLE_VIEWS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async setArticleDetailCommentsPageNumber({commit}, page) {
        commit('SET_ARTICLE_DETAIL_COMMENTS_PAGE_NUMBER', page)
    },
    async setHashtagPageNumber({commit},page){
        commit('SET_HASHTAG_PAGE_NUMBER', page)
    },
    async setBreakingPageNumber({commit},page){
        commit('SET_BREAKING_PAGE_NUMBER', page)
    },



}
