<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
  
</template>
<script>







export default {
  name: 'App',

  //data
  data(){
    return{

     }
   },

  //register 
 components: {
  

},

}

</script>


<style lang="scss">
  @import "assets/css/custom-style.css";
  @import "assets/css/custom-summernote-style.css";
  @import "assets/slim.min.css";
  @import "assets/css/custom-media-queries-style.css";
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }


</style>
