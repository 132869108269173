<template>
    <div class="parentCategories">
        <!-- Button trigger modal -->
        <!-- Modal -->
        <div class="modal fade" id="parentModal" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog main-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header select-category-modal position-relative">
                        <h4 class="modal-title w-100 text-center" id="myModalLabel">Select Parent Categories</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bodyModal">
                        <div class="country-modal-wrapper">
                            <CustomSelectCountry @updateCountyId="updateCountyId"/>
                        </div>
                        <form method="post">
                            <div class="form-group-wrapper">
                                <div class="form-group">
                                    <input v-model="searchCategory" type="text" @keyup="searchList" @click="openList"
                                           class="form-control" id="dropdown-select"/>
                                    <div class="custom-dropdown">
                                        <ul>
                                            <li v-for="category in getCustomCategories" :key="category.code">
                                                <span class="cursor" @click="selectCustomCategory(category)">{{category.name}}</span>
                                                <span style="cursor: pointer;" @click="viewParentCategory(category)">view</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="select-close"></div>
                        <div class="d-flex">
                            <div v-for="categories in getCategories"
                                 :key="categories.id+'_'+Math.random()">
                                <div class="tags">
                                    <div v-for="category in categories" :key="category.id">
                                        <button v-if="category.id !==0" @click="deleteCategory(category)" class="button">{{category.category_name}} <span aria-hidden="true">×</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="saveTags" class="btn btn-light parentFooter text-muted"
                                data-dismiss="modal">Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import CustomSelectCountry from "../CountrySelect/CustomCountrySelect";
    // import CustomCategoryTag from "../CustomCategoryTag/CustomCategoryTag";
    import {mapGetters} from 'vuex';
    import $ from 'jquery';

    export default {
        name: "ParentCategories",
        data() {
            return {
                selectWorldCategories: [],
                searchCategory: "",
            }
        },
        components: {
            CustomSelectCountry
        },
        computed: {
            ...mapGetters({
                getCustomCategories: "category/getCustomCategories",
                getCategories: "category/getCategories",
                defaultCustomCountry: "country/defaultCustomCountry",
                activeCategory: "category/getActiveCategory"
            })
        },
        methods: {
            getCustomParentCategories(id) {
                this.$store.dispatch('category/customParentCategories', id)
            },
            openList() {
                $('.custom-dropdown').toggleClass('show');
            },
            searchList() {
                console.log("this.searchCategory", this.searchCategory);
                if (this.searchCategory !== '') {
                    this.$store.dispatch('category/searchCustomParentCategories', this.searchCategory)
                } else {
                    this.getCustomParentCategories(this.defaultCustomCountry.id)
                }
            },
            updateCountyId(id) {
                this.getCustomParentCategories(id)
            },
            selectCustomCategory(obj) {
                let payload = {
                    category_id: obj.code,
                    type: true,
                    category_name: obj.name
                }
                this.$store.dispatch('category/addFavouriteCategory', payload).then(() => {
                    this.$emit('emitUpdateFavouriteCategory', true);
                })
            },
            saveTags() {
                window.location.reload();
            },
            viewParentCategory(category) {
                // let obj = {id: category.code, category_name: category.name};
                let payload = {id: category.code}

                this.$store.dispatch("category/getCategory", payload).then(() => {
                    const route = this.$router.resolve({
                        path: '/home',
                        params: {category: category},
                    });
                    window.open(route.href, '_blank');
                });

                this.$emit("emitActiveCategory", category)
                // localStorage.setItem('setCategory',JSON.stringify(obj))
                // let customCountry = localStorage.getItem('customCountry');
                // let setCountry = localStorage.setItem('country',customCountry)
                // this.$store.dispatch('category/updateCustomSelectedCategory').then(()=>{
                //     const route = this.$router.resolve({
                //         path: '/home',
                //         params: {category:category},
                //     });
                //     window.open(route.href, '_blank');
                // });
            },
            deleteCategory(category){
                let payload = {
                    category_id: category.id,
                    category_name: category.category_name,
                    type:false
                }
                this.$store.dispatch('category/addFavouriteCategory',payload).then(()=>{
                    this.$emit('emitUpdateFavouriteCategory', true);
                })
            }
        },
        mounted() {
            this.getCustomParentCategories(this.defaultCustomCountry.id)
        }

    }
</script>

<style>

    .main-dialog {
        max-width: 700px;
        width: 100%;
    }

    .bodyModal {
        padding: 0px 12px !important;
    }

    .parentFooter {
        border: 1px solid #588ACE;
        font-size: 14px;
    }

    .parentFooter:hover {
        background: #f8f9fa;
        border: 1px solid #588ACE;
    }

    .country-modal-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .modal-title {
        font-size: 15px;
        font-weight: 500;
    }

    .select-close .button-close {
        background-color: #e4e4e4;
        padding: 3px 6px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #aaa;
        color: #999;
    }

    .close-sign {
        color: #999;
        font-size: 20px;
        font-weight: bold;
    }

    .button-close {
        display: flex;
        margin-bottom: 15px;
    }

    /*.form-group-wrapper {*/
    /*    max-width: 300px;*/
    /*    margin-top: 30px;*/
    /*}*/
    .form-group-wrapper ul {
        padding-left: 0;
    }

    .form-group-wrapper ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }

    .form-group-wrapper ul li:hover {
        background-color: #D4D5D6;
    }

    /*-- focus--*/
    .form-control:hover,
    .form-control:active,
    .form-control:focus {
        border-color: #ced4da !important;
    }

    .custom-dropdown {
        display: none;
    }

    .show {
        display: block;
    }

    .cursor {
        cursor: pointer;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
    }

    .button {
        padding: 10px 25px;
        border-radius: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        background-color: #D4D5D6;
        border: none;
        color: black;
    }
</style>