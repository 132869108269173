import axios from "../../axios.js";

export default {
    async getCategories({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/category/categories/' + payload.id+'?type='+payload.type).then((response) => {
                commit('GET_CATEGORIES',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async customParentCategories({commit}, countryId) {
        console.log("checking country id",countryId)
        return new Promise((resolve, reject) => {
            axios.get('/category/parent/categories/'+countryId).then((response) => {
                commit('CUSTOM_PARENT_CATEGORIES',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async searchCustomParentCategories({commit},search){
        commit('SEARCH_CUSTOM_PARENT_CATEGORIES',search)
    },

    async addFavouriteCategory({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/category/favourite',payload).then((response) => {
                if(payload.type === true){
                    commit('ADD_FAVOURITE_CATEGORY',response)
                }else{
                    commit('DELETE_FAVOURITE_CATEGORY',payload)
                }
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getWorldCategories({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/category/categories/' + countryId +'?type=home').then((response) => {
                commit('GET_WORLD_CATEGORIES',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getWorldHashTags({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/hashtag/'+countryId).then((response) => {
                commit('GET_WORLD_HASH_TAG',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getCountryCategories({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/category/categories/'+countryId+'?type=home').then((response) => {
                commit('GET_COUNTRY_CATEGORIES',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getCountryHashTags({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/hashtag/'+countryId).then((response) => {
                commit('GET_COUNTRY_HASH_TAG',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },


    async emptyCategories({commit}) {
        commit('EMPTY_CATEGORIES')
    },
    async updateCustomSelectedCategory({commit}){
        commit('UPDATE_CUSTOM_SELECTED_CATEGORIES');
    },

    async updateType({commit},type){
        commit('UPDATE_TYPE',type);
    },

    updateActiveCategory({commit},category){
        commit('UPDATE_ACTIVE_CATEGORY',category);
    },

    getCategory({commit},payload){
        return new Promise((resolve, reject) => {
            axios.get('/category/'+payload.id).then((response) => {
                commit('UPDATE_ACTIVE_CATEGORY',response.data.data)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    getTrendingHashTags({commit},payload){
        return new Promise((resolve, reject) => {
            axios.get('/article/category/trending/'+payload.countryId+'?type='+payload.type).then((response) => {
                commit('GET_TRENDING_HASHTAGS',response);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    }



}


