<template>
  <div>
    <div class="walletNavbar">
      <b-tabs>
        <b-tab v-for="nav in navBarData" @click="showmethod(nav.id)" :key="nav.id+'_'+Math.random()" :title="nav.title">
          <b-card-text>
            <div v-if="componentPages === 1">
              <WalletTable />
            </div>
            <div v-if="componentPages === 2">
              <WalletTable />
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
 

</template>

<script>
import  WalletTable from "../WalletTable/WalletTable"
export default {
    name:"WalletNavbar",
    components:{
       WalletTable,
    },
    props: {
    navBarData: Array,
  },

  data() {
    return {
      componentPages: 1,
    };
  },

  methods: {
    renderComponent(component) {
      console.log(component);
      this.componentPages = component;
    },
    showmethod(value) {
      this.componentPages = value
    },
  },
  
};
</script>

<style scoped>

.walletNavbar .nav-tabs {
  margin-bottom: 1rem;
}

.walletNavbar .nav-tabs .nav-link {
   
  border: 0;
  text-transform: capitalize;
  font-size: 18px;
}

.walletNavbar .nav-tabs .nav-link:hover {
  color: #888 !important;
 
}


.walletNavbar .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f5f8fa;
    border-color: #dee2e6 #dee2e6 #f5f8fa;
}
</style>
