import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'

import "@popperjs/core";
import 'bootstrap';
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import App from './App.vue'
import router from './router'
import store from './store/store'
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// import Select2 from  'v-select2-component';
// Vue.component('Select2', Select2);
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false

//set globe variable
let country = JSON.parse(localStorage.getItem("country"));
if (!country) {
  localStorage.setItem("country", JSON.stringify({ id: 1, text: "world" }));
  country = JSON.parse(localStorage.getItem("country"));
}
Vue.prototype.$country = country

//set country for custom home page//

let customCountry = JSON.parse(localStorage.getItem("customCountry"));
if (!customCountry) {
  localStorage.setItem("customCountry", JSON.stringify({ id: 1, text: "world" }));
  customCountry = JSON.parse(localStorage.getItem("customCountry"));
}
Vue.prototype.$customCountry = customCountry




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
