<template>
    <div class="subscribe-category-modal">


        <!-- Modal -->
        <div class="modal fade" id="subscribe-category-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" id="small-sub">
                <div class="modal-content">
                    <div class="modal-header pt-2 pb-0 subscribe-head">
         <button data-dismiss="modal" class="close-btn-sub"> <i class="fa-solid fa-xmark"  aria-label="Close"></i></button>
       
      </div>
      <div class="modal-body archive-body">
                        <p class="archive-para mb-0">
                            Would you like to subscribe to {{category.category_name}}?
                        </p>
                    </div>
                   
                    <div class="modal-footer subscribe-footer pt-0">
                       <!----<button @click="subscirbeCategory" type="button" class="btn  archive-button">
                        Subscribe
                        </button>
                        <button data-dismiss="modal" type="button" class="cancel archive-button">Cancel</button>-->

                         <button type="button" @click="subscirbeCategory" class="btn btn-success archive-button" >Subscribe</button>
                        <button type="button" class="btn  btn-info  archive-button"  style=" background-color: #C1C1C1;">Cancel</button>
                         </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "Archive",
        props: {
            category: Object
        },
        data() {
            return {}
        },
        methods: {
            subscirbeCategory() {
                let payload = {
                    category_id: this.category.id,
                    type: true,
                    category_name: this.category.category_name
                }
                this.$store.dispatch('category/addFavouriteCategory', payload).then(response => {
                    this.$store.dispatch("category/updateActiveCategory",response.data.category)
                    this.closeModal('subscribe-category-modal');
                })
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
        }
    }
</script>

