<template>
    <div id="drafts">
        <div class="row mt-4">
            <div class="col-lg-8 col-md-8 col-sm-12 order-xxl-first order-md-first order-sm-last  order-last">
                <SubHeading category='Draft' link=""/>
                <div v-for="cardData in getDraftAndUpcoming" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :cardData="cardData" :isEdit="true"/>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getDraftArticles"
                ></infinite-loading>
            </div>

        </div>
    </div>
</template>

<script>
    import BlogCard from "../components/BlogCard/BlogCard";
    import SubHeading from '../components/Headings/SubHeading';
    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex'

    export default {
        name: 'draft',
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Drafts'
        },

        components: {
            BlogCard, SubHeading, InfiniteLoading,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                getDraftAndUpcoming: 'article/getDraftAndUpcoming',
                getDraftPage: 'article/getDraftPage',
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                categoryArticle: "article/getCategoryArticle",
            })
        },
        methods: {
            getDraftArticles($state) {
                let payload = {
                    page: this.getDraftPage,
                    $state: $state
                }
                this.$store.dispatch('article/getDraftAndUpcoming', payload)
            }
        },
        mounted() {
        }
    }
</script>


<style scoped>

</style>
