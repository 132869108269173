<template>
    <div id="profile">

        <div class="row  mt-4">
            <div id="profile-cover">
                <img :src="getUserSEC1Image()" alt="section-one" width="100%" height="250" style="object-fit: cover">
            </div>
        </div>

        <div id="profile-img" class="row  mt-3 mb-3">
            <div class="col-sm-12 profile-img">
                <div id="user-img" class=" d-inline ">
                    <img :src="getUserProfileImage()" width="60" height="60" class="rounded-circle mr-3"
                         alt="profile-picture">
                </div>
                <div class="user-name d-inline">
                    <table class="d-inline">
                        <tr>
                            <td>
                                <h3 class="d-inline">{{getUserName()}}</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="subscriber">
                                <span class="badge badge-pill badge-info"
                                      style="color: #fff;background-color: #17a2b8;"><i>Subscribers: {{getTotalUserSubscribers()}} </i></span>
                                    <a v-bind:href="getFbUrl()" target="_blank" class="social-icon">
                                        <i class="fa-brands fa-facebook-f icon"></i>
                                    </a>
                                    <a v-bind:href="getTwitterUrl()" target="_blank"
                                       class="social-icon">
                                        <i class="fa-brands fa-twitter icon"></i>
                                    </a>
                                    <a v-bind:href="getYoutubeUrl()" target="_blank"
                                       class="social-icon">
                                        <i class="fa-brands fa-youtube fa-fw icon"></i>
                                    </a>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 profile-text">
                <h6>{{getDescription()}}</h6>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Profile',
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Profile'
        },
        components: {},
        data() {
            return {
                navBarArray: [{id: 1, title: "settings"}],
                getAuthUser: null
            };
        },
        computed: {},
        methods: {
            getUserProfile() {
                let profile = localStorage.getItem('profile');
                if (!profile) {
                    this.$router.push('/home');
                }
                this.getAuthUser = JSON.parse(profile);
            },
            getUserSEC1Image() {
                return this.getAuthUser ? this.getAuthUser.sec_1 : '';
            },
            getUserProfileImage() {
                return this.getAuthUser ? this.getAuthUser.profile_pic : '';
            },
            getUserName() {
                return this.getAuthUser ? this.getAuthUser.name : '';
            },
            getTotalUserSubscribers(){
                return this.getAuthUser ? this.getAuthUser.total_user_subscribers : 0
            },
            getFbUrl() {
                return this.getAuthUser ? this.getAuthUser.socail_user.fb_url : '';
            },
            getTwitterUrl() {
                return this.getAuthUser ? this.getAuthUser.socail_user.twitter_url : '';
            },
            getYoutubeUrl(){
                return this.getAuthUser ? this.getAuthUser.socail_user.youtube_url : 0
            },
            getDescription(){
                return this.getAuthUser ? this.getAuthUser.description : ""
            }

        },
        mounted() {
            this.getUserProfile()
        }
    }

</script>

<style scoped>
    #profile-cover {
        height: 250px;
        width: 100%;
    }

    #profile-img{
        text-align: center;
    }
    .user-profile-table{
        text-align: initial;
    }
    .user-name h3 {
        font-size: 25px;
    }

    .social-icon .icon {
        color: gray;
        width: 23px;
        font-size: 14px;
        text-align: center;
    }

    .profile-text h6 {
        font-size: 0.9rem;
    }

</style>