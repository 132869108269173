<template>
    <div class="row">
        <div class="col-lg-12">
            <div
                    v-for="comment in getAllCommentsReceived"
                    :key="comment.id+'_'+Math.random()">
                <userSendComment :comment="comment"/>
            </div>
            <infinite-loading
                    spinner="spiral"
                    @infinite="retrievedAllCommentsReceived"
            ></infinite-loading>
        </div>
    </div>

</template>
<script>
    import InfiniteLoading from "vue-infinite-loading";

    import userSendComment from '../UserComments/UserSendComment'
    import {mapGetters} from "vuex";

    export default {
        components: {
            userSendComment,
            InfiniteLoading
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                getAllCommentsReceived: "article/getAllCommentsReceived",
                getAllCommentsReceivedPage: "article/getAllCommentsReceivedPage",
            }),
        },
        methods: {
            retrievedAllCommentsReceived($state) {
                let payload = {
                    page: this.getAllCommentsReceivedPage,
                    $state: $state,
                };
                this.$store.dispatch("article/getAllCommentsReceived", payload);
            },
        },
        mounted() {
            this.retrievedAllCommentsReceived();
        }
    }

</script>
<style scoped="">

</style>