<template>

  <div :id="element">
    <slot></slot>
  
  </div>
 
</template>

<script>
// eslint-disable-next-line no-unused-vars

import $ from "jquery";
import "summernote/dist/summernote-lite.css";
import "summernote/dist/summernote-lite.js";
export default {
  name: "Summernote",
  components:{},
  data() {
    return {
      element: `summernote_id`,
    };
  },
  props: {
    config: {
      type: Object,
    },
    ready: {
      type: Function,
    },
  },
  mounted() {
    this.$nextTick(() => {
      let defaultConfig = {
        //lang: "es-ES",
        // placeholder: 'Enter Text',
        height: 1000,
        // width:730,
        disableDragAndDrop: true,
        focus: true,
        buttons: {
          h1: () => {
            const summernote = this;
            const ui = this.ui();
            const buttonNew = ui.button({
              contents: '<b>H1</b>',
              container: false,
              tooltip: 'Header 1',
              click: function () {
                summernote.summernote('editor.createRange');
                let h1 = summernote.summernote('editor.formatH1');
                summernote.summernote('editor.styleFromNode', h1);
              }
            });

            return buttonNew.render();   // return button as jquery object
          },
          h2: () => {
            const summernote = this;
            const ui = this.ui();
            const buttonNew = ui.button({
              contents: '<b>H2</b>',
              container: false,
              tooltip: 'Header 2',
              click: function () {
                summernote.summernote('editor.createRange');
                let h2 = summernote.summernote('editor.formatH2');
                summernote.summernote('editor.styleFromNode', h2);
              }
            });

            return buttonNew.render();   // return button as jquery object
          },
          plain: () => {
            const summernote = this;
            const ui = this.ui();
            const buttonNew = ui.button({
              contents: '<b>PT</b>',
              container: false,
              tooltip: 'plain text',
              click: function () {
                summernote.summernote('editor.createRange');
                let h5 = summernote.summernote('editor.formatH5');
                summernote.summernote('editor.styleFromNode', h5);
              }
            });

            return buttonNew.render();   // return button as jquery object
          },
          quote: (context) => {
            const ui = this.ui();
            const button = ui.button({
              contents: '<b>Quote</b>',
              container: false,
              tooltip: 'BlockQuote',
              click: function () {
                var range = context.invoke('editor.createRange');
                range.pasteHTML('<blockquote class="quote"><span>' + range.toString() + '</span></blockquote>');
                range.deleteContents(range.toString());

              }
            });

            return button.render();
          },
          tweet: () => {
            const ui = this.ui();
            const button = ui.button({
              contents: '<i class="fa-brands fa-twitter"></i>',
              container: false,
              tooltip: 'Tweet',
              click: function () {
                $('#tweetModal').modal('show');
              }
            });

            return button.render();
          },
          skim: (context) => {
            const summernote = this;
            const ui = this.ui();
            const buttonNew = ui.button({
              contents: '<b>Skim</b>',
              container: false,
              tooltip: 'Highlight Text',
              click: function () {
                var range = context.invoke('editor.createRange');
                summernote.summernote('editor.insertText', '<SKIM>' + range.toString() + '</SKIM>');
              }
            });
            return buttonNew.render();
          }
        },
        callbacks: {
          onInit: () => {
            this.$emit("ready", this);
          },
          onChange: (contents) => {
            this.$emit("change", contents);
          },
          onEnter: () => {
            this.$emit("enter");
          },
          onFocus: () => {
            this.$emit("focus");
          },
          onBlur: () => {
            this.$emit("blur");
          },
          onKeyup: (e) => {
            let t = e.currentTarget.innerText;
            $("#maxContentPost").text("characters: " + t.trim().length);
            this.$emit("keyup", e);
          },
          onKeydown: (e) => {
            let t = e.currentTarget.innerText;
            if (t.trim().length >= 40000) {
              if (
                      e.keyCode !== 8 &&
                      !(e.keyCode >= 37 && e.keyCode <= 40) &&
                      e.keyCode !== 46 &&
                      !(e.keyCode === 88 && e.ctrlKey) &&
                      !(e.keyCode === 67 && e.ctrlKey)
              )
                e.preventDefault();
            }
            this.$emit("keydown", e);
          },
          onPaste: (e) => {
            let t = e.currentTarget.innerText;
            let bufferText = (
                    (e.originalEvent || e).clipboardData || window.clipboardData
            ).getData("Text");
            e.preventDefault();
            var maxPaste = bufferText.length;
            if (t.length + bufferText.length > 40000) {
              maxPaste = 40000 - t.length;
            }
            if (maxPaste > 0) {
              document.execCommand(
                      "insertText",
                      false,
                      bufferText.substring(0, maxPaste)
              );
            }
            $("#maxContentPost").text("characters: " + t.length);
            this.$emit("paste", e);
          },
          onImageUpload:(files) => {
            const summernote = this;
            console.log('test',files[0]);
            let image = files[0];
            console.log('image is here',image);
            let data = new FormData();
            data.append('image',image);
            console.log('testing form data',data);
            if(image){
              this.$store.dispatch('article/uploadEditorImage', data).then((response) => {
                console.log("response 1234", response);
                var imgNode = document.createElement('img');
                var imgId = 'img-' + response.data.randomno;
                imgNode.src = response.data.url;
                imgNode.width = '515';
                imgNode.id = imgId;
                imgNode.setAttribute('class',imgId);
                summernote.summernote('insertNode', imgNode);
                let ImageId = $("#"+imgId);
                ImageId.css({'max-height':'600px','object-fit':'contain','text-align':'center', 'margin': 'auto', 'display': 'block'});
                ImageId.parent().after().append('<br/>');
              });
            }
          },
        },
      };
      let config = Object.assign({}, defaultConfig, this.config);
      $("#" + this.element).summernote(config);
    });
  },
  methods: {
    summernote(...args) {
      $("#" + this.element).summernote(...args);
    },
    ui() {
      return $.summernote.ui;
    },
  },
};
</script>
<style scoped>
.summernote-img {
  max-width: 100%; 
}
.note-editor{
  margin-bottom: 100px !important;
}

</style>
