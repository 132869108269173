<template>
    <div>
        <div class="row mt-4">
            <div class="main-home col-lg-8 col-md-12 col-sm-12 order-lg-first order-md-last">
                <SubHeading :category="getHashTagData.hash_tag_name" :hashTag="getHashTagData.category.category_name"
                            link=""/>
                <div v-for="cardData in getAllHashtagArticles" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :cardData="cardData"/>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getAllHashtagArticle"
                ></infinite-loading>
            </div>
            <div :style="' left:'+mainHome+'px'" class="menu-home col-lg-4 col-m-12 col-sm-12  pl-lg-5  order-xxl-last order-lg-last    order-sm-first ordr-first">

                <CountrySelect :isCustom="type" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                <br>
                <BlogCategory :type="type" @emitActiveCategory="emitActiveCategory"/>
            </div>
        </div>


    </div>
</template>

<script>
    import BlogCategory from "../components/BlogCategory/BlogCategory";
    import CountrySelect from "../components/CountrySelect/CountrySelect";
    import BlogCard from "../components/BlogCard/BlogCard";
    import SubHeading from "../components/Headings/SubHeading";
    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex';
    import $ from "jquery";

    export default {
        name: "HashtagDetail",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Hashtag'
        },
        components: {
            BlogCard,
            SubHeading,
            InfiniteLoading,
            BlogCategory,
            CountrySelect
        },
        data() {
            return {
                type: "hash",
                windowWidth: 0,
                mainHome: 0,
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                categoryArticle: "article/getCategoryArticle",
                pageNumber: "article/getPageNumber",
                activeCategory: "category/getActiveCategory",
                getAllHashtagArticles: 'article/getAllHashtagArticles',
                getHashtagPage: 'article/getHashtagPage',
                getHashTagData: 'article/getHashTagData'
            })
        },
        methods: {
            getAllHashtagArticle($state) {
                let payload = {
                    page: this.getHashtagPage,
                    $state: $state,
                    countryId: this.defaultCountry.id,
                    slug: this.getHashTagData.slug,
                }
                this.$store.dispatch('article/getAllHashtagArticles', payload)

            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.type
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            getTrendingHashtag() {
                let payload = {
                    countryId: this.defaultCountry.id,
                    type: this.type
                }
                this.$store.dispatch('category/getTrendingHashTags', payload);
            },
            updateCountyId() {
                this.$router.push('/home')
            },
            emitActiveCategory() {
                this.$router.push('/home')
            },
            calculate() {
                this.windowWidth = window.innerWidth;
                let divWidth = $(".main-home");
                if (divWidth.offset()) {
                    this.mainHome = divWidth.offset().left + divWidth.width() + 55;
                }
            }
        },
        mounted() {
            console.log("mount");
            this.$store.dispatch("article/setHashtagPageNumber", 1);
            this.getTrendingHashtag();
            this.calculate();
            setInterval(() => {
                this.calculate();
            }, 100)
        }
    };
</script>

