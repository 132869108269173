<template>
    <div class="search">
        <div class="">
            <searchNav :navBarData="navBarArray"/>
        </div>
    </div>
</template>

<script>
    import searchNav from '../components/Navbars/SearchNavbar.vue';

    export default {
        name: 'searchUser',
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Search'
        },
        components: {searchNav},
        data() {
            return {
                navBarArray: [{id: 1, title: "Articles"}, {id: 2, title: "user"}],
            };
        },
        methods: {
            getArticleAndUser() {
                let search = localStorage.getItem('search');
                if (search) {
                    this.$store.dispatch('article/getSearchedArticleAndUser', {search: search})
                }
            }
        },
        mounted() {
            this.getArticleAndUser();
        }
    }


</script>

