<template>
    <table class="blog-info mb-2 w-100 pt-2 mt-lg-0">
        <tbody>
        <tr>
            <td class="blog-small-image">
                <a href="javascript:void(0)" onclick="return false;" target="_top" class="cursor"
                   @click="profile">
                    <img :src="articleDetail.user.profile_pic" width="40" height="40"
                         class="rounded-circle">
                </a>
            </td>
            <td class="pl-2 pr-2">
                <a href="javascript:void(0)" onclick="return false;" target="_top" class="user cursor"
                   @click="profile">
                    <label class=" mb-0 mr-2 text-dark">{{articleDetail.user.name}}</label>
                </a>
                <span v-if="isLogin">
                                    <a class="social-icon"><i class="fa-brands fa-facebook-f"></i></a>
                                    <a href="#" class="social-icon"><i class="fa-brands fa-twitter"></i></a>
                                    <a class="social-icon"><i class="fa-brands fa-youtube fa-fw ml-1"></i></a>
                                </span>
                <div v-if="isLogin" class="subscribe">
                    <Subscriber
                            type="articleDetail"
                            :loginUserId="getAuthUser.id"
                            :userId="articleDetail.user.id"
                            :totalUserSubscribers="articleDetail.user.total_user_subscribers"
                            :IsSubscribe="articleDetail.user.is_subscribe"
                    />
                </div>
            </td>
            <td></td>
            <td></td>
            <td class="show-date float-right">
                <label class="float-right mb-0 mt-2">{{articleDetail.created_at}}</label>
                <br/>
                <label class="mb-0 updated-date">Updated:{{articleDetail.article_last_updated}}</label>
            </td>
            <td></td>
        </tr>
        </tbody>
    </table>

</template>

<script>
    import Subscriber from "../User/Subscriber";
    export default {
        components:{
            Subscriber,
        },
        props:{
            isLogin:Boolean,
            articleDetail:Object,
            getAuthUser:Object
        },
        methods:{
            profile(){
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            }
        }


    }
</script>

<style>

</style>