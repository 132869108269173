export default {
    GET_ARTICLE(state, response) {
        let articles = response.data.data;
        console.log('articles', articles)
        if (articles.length > 0) {
            // response.data.meta.last_page
            if (response.data.meta.last_page >= state.paginate.page) {
                state.paginate.page += 1;
                state.articles.push(...articles)
                // response.data.data.map(x=>{
                // let index = state.articles.findIndex(y=>(y.id === x.id))
                // if(index === -1){
                //     state.articles.push(x)
                // }
                // })
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
        state.articles.map(article => {
            article.article_category = article.article_category.filter(x =>
                x.article !== null
            );
            return article
        })
    },

    SET_PAGE_NUMBER(state, page) {
        state.paginate.page = page;
        state.articles = []
        state.breaking = []
        state.upcoming = []

    },
    SET_CATEGORY_PAGE_NUMBER(state, page) {
        state.categoryArticle.page = page;
        state.categoryArticle.articles = []
        state.categoryArticle.breaking = []
        state.categoryArticle.upcoming = []
    },
    UPDATED_ARTICLE(state, response) {
        state.articles = [];
        state.paginate.page = 1;
        state.articles = response.data.data;
    },
    GET_CATEGORY_ARTICLE(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.categoryArticle.page) {
                state.categoryArticle.page += 1;
                state.categoryArticle.articles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
        state.categoryArticle.articles.map(article => {
            article.article_category = article.article_category.filter(x =>
                x.article !== null
            );
            return article
        })
    },
    GET_BREAKING_AND_UP_COMING_ARTICLE(state, response) {
        state.breaking = response.data.data.breaking;
        state.upcoming = response.data.data.upcoming;
        state.subscribed = response.data.data.subscribed;
    },
    GET_CATEGORY_BREAKING_AND_UP_COMING_ARTICLE(state, response) {
        state.categoryArticle.page = 1;
        state.categoryArticle.articles = []
        state.categoryArticle.breaking = []
        state.categoryArticle.upcoming = []
        state.categoryArticle.subscribed = []
        state.categoryArticle.breaking = response.data.data.breaking;
        state.categoryArticle.upcoming = response.data.data.upcoming;
        state.categoryArticle.subscribed = response.data.data.subscribed;
    },
    ARTICLE_DETAIL(state, response) {
        state.articleDetail = response.data.data;
    },
    IMAGE_CAPTION() {
    },
    EMPTY_ARTICLE_DETAIL(state) {
        state.articleDetail = {id: ''}
    },
    UPLOAD_EDITOR_IMAGE() {
    },
    GET_DRAFT_AND_UPCOMING(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.draftPage) {
                state.draftPage += 1;
                state.draftAndUpcoming.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_PUBLISHED_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.publishPage) {
                state.publishPage += 1;
                state.publishedArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    ADD_DRAFT_ARTICLE(state, response) {
        let index = state.draftAndUpcoming.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.draftAndUpcoming[index] = response.data.data
        } else {
            state.draftAndUpcoming.unshift(response.data.data);
        }
        let index2 = state.draftAndUpcoming.findIndex(el => el.status.id === 7);
        if (index2 !== -1) {
            console.log('exect', state.draftAndUpcoming[index2]);
            state.draftAndUpcoming.splice(index2, 1);
        }
    },
    ADD_PUBLISH_ARTICLE(state, response) {
        let index = state.publishedArticles.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.publishedArticles[index] = response.data.data
        } else {
            state.publishedArticles.unshift(response.data.data);
        }
        let index2 = state.publishedArticles.findIndex(el => el.status.id === 5);
        if (index2 !== -1) {
            console.log('exect', state.publishedArticles[index2]);
            state.publishedArticles.splice(index2, 1);
        }
    },
    GET_ALL_BREAKING_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.breakingPage) {
                state.breakingPage += 1;
                state.allBreakingArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ALL_UPCOMING_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.upcomingPage) {
                state.upcomingPage += 1;
                state.allUpcomingArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.complete();
            }
        }
    },

    ADD_COMMENT(state, response) {
        if (response.data.data.self_parent_id) {
            if (state.articleDetail.id) {
                let index = state.articleDetailComments.findIndex(x => x.id === response.data.data.self_parent_id);
                if (index !== -1) {
                    state.articleDetailComments[index].replies.unshift(response.data.data);
                }
            }
            let index1 = state.allCommentsSend.findIndex(x => x.id === response.data.data.self_parent_id);
            if (index1 !== -1) {
                state.allCommentsSend[index1].replies.unshift(response.data.data);
            }
            let index2 = state.allCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
            if (index2 !== -1) {
                state.allCommentsReceived[index2].replies.unshift(response.data.data);
            }
            let index3 = state.writerReplyCommentsSend.findIndex(x => x.id === response.data.data.self_parent_id);
            if (index3 !== -1) {
                state.writerReplyCommentsSend[index3].replies.unshift(response.data.data);
            }
            let index4 = state.writerReplyCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
            if (index4 !== -1) {
                state.writerReplyCommentsReceived[index4].replies.unshift(response.data.data);
            }
        } else {
            if (state.articleDetail.id) {
                state.articleDetailComments.unshift(response.data.data)
            }
        }
    },
    ADD_LIKE_OR_DISLIKE_COMMENT(state, response) {
        if (state.articleDetail.id) {
            let index = state.articleDetailComments.findIndex(x => x.id === response.data.data.article_comment.id);
            if (index !== -1) {
                state.articleDetailComments[index].is_like_dislike = response.data.data.article_comment.is_like_dislike;
                state.articleDetailComments[index].total_like = response.data.data.article_comment.total_like;
                state.articleDetailComments[index].total_dislike = response.data.data.article_comment.total_dislike;
            }
        }
        let index1 = state.allCommentsSend.findIndex(x => x.id === response.data.data.article_comment.id);
        if (index1 !== -1) {
            state.allCommentsSend[index1].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.allCommentsSend[index1].total_like = response.data.data.article_comment.total_like;
            state.allCommentsSend[index1].total_dislike = response.data.data.article_comment.total_dislike;
        }
        let index2 = state.allCommentsReceived.findIndex(x => x.id === response.data.data.article_comment.id);
        if (index2 !== -1) {
            state.allCommentsReceived[index2].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.allCommentsReceived[index2].total_like = response.data.data.article_comment.total_like;
            state.allCommentsReceived[index2].total_dislike = response.data.data.article_comment.total_dislike;
        }
        let index3 = state.writerReplyCommentsSend.findIndex(x => x.id === response.data.data.article_comment.id);
        if (index3 !== -1) {
            state.writerReplyCommentsSend[index3].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.writerReplyCommentsSend[index3].total_like = response.data.data.article_comment.total_like;
            state.writerReplyCommentsSend[index3].total_dislike = response.data.data.article_comment.total_dislike;
        }
        let index4 = state.writerReplyCommentsReceived.findIndex(x => x.id === response.data.data.article_comment.id);
        if (index4 !== -1) {
            state.writerReplyCommentsReceived[index4].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.writerReplyCommentsReceived[index4].total_like = response.data.data.article_comment.total_like;
            state.writerReplyCommentsReceived[index4].total_dislike = response.data.data.article_comment.total_dislike;
        }
    },
    GET_ALL_COMMENTS_SEND(state, response) {
        // if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.allCommentsSendPage) {
                state.allCommentsSendPage += 1;
                state.allCommentsSend.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.complete();
            }
        // }
    },
    GET_ALL_COMMENTS_RECEIVED(state, response) {
        // if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.allCommentsReceivedPage) {
                state.allCommentsReceivedPage += 1;
                state.allCommentsReceived.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.complete();
            }
        // }
    },
    GET_WRITER_REPLY_COMMENTS_SEND(state, response) {
        state.writerReplyCommentsSend = response.data.data
    },
    GET_WRITER_REPLY_COMMENTS_RECEIVED(state, response) {
        state.writerReplyCommentsReceived = response.data.data
    },
    GET_ARTICLE_SEARCH(state, response) {
        state.articleSearch = response.data.data
    },

    GET_ARTICLE_SEARCHED(state, response) {
        state.searchArticles = [];
        state.searchUsers = [];
        state.searchArticles = response.data.data.articles;
        state.searchUsers = response.data.data.users
    },
    EMPTY_ARTICLE_SEARCH(state) {
        state.articleSearch = []
    },
    GET_ALL_HASHTAG_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.getHashtagPage) {
                state.getHashtagPage += 1;
                state.allHashtagArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ARTICLE_DETAIL_COMMENTS(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.articleDetailCommentsPageNumber) {
                state.articleDetailCommentsPageNumber += 1;
                state.articleDetailComments.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    UPDATE_ARTICLE_USER_SUBSCRIBER(satate,response){
        satate.articleDetail.user.is_subscribe = response.data.data.is_subscribe
        satate.articleDetail.user.total_user_subscribers = response.data.data.total_user_subscribers
    },
    UPDATE_SEARCH_USER_SUBSCRIBER(state,response){
        let index = state.searchUsers.findIndex(x=> x.id === response.data.data.user_id);
        if(index !== -1){
            state.searchUsers[index].is_subscribe = response.data.data.is_subscribe
            state.searchUsers[index].total_user_subscribers = response.data.data.total_user_subscribers
        }
    },
    ARCHIVE_PUBLISH_ARTICLE(state,response){
        let index = state.publishedArticles.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.publishedArticles.splice(index,1)
        }
    },
    ARCHIVE_DRAFT_ARTICLE(state,response){
        let index = state.draftAndUpcoming.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.draftAndUpcoming.splice(index,1)
        }
    },
    SKIM_LIKE_DISLIKE_ARTICLE(state,response){
        let index = state.categoryArticle.articles.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.articles[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.articles[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.articles[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SKIM_LIKE_DISLIKE_BREAKING_ARTICLE(state,response){
        let index = state.categoryArticle.breaking.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.breaking[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.breaking[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.breaking[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SKIM_LIKE_DISLIKE_UPCOMING_ARTICLE(state,response){
        let index = state.categoryArticle.upcoming.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.upcoming[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.upcoming[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.upcoming[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SET_ARTICLE_DETAIL_COMMENTS_PAGE_NUMBER(state,page){
        state.articleDetailComments = []
        state.articleDetailCommentsPageNumber = page
    },
    SET_HASHTAG_PAGE_NUMBER(state,page){
        state.allHashtagArticles = []
        state.getHashtagPage = page
    },
    SET_BREAKING_PAGE_NUMBER(state,page){
        state.allBreakingArticles = []
        state.breakingPage = page
    },
    ADD_ARTICLE_VIEWS(state,response){
        state.articleDetail.total_views = response.data.data.total_views
        state.articleDetail.skim_count = response.data.data.skim_count
        state.articleDetail.total_views_skim = response.data.data.total_views_skim
    },
    SET_HASH_TAG_DATA(state,data){
      localStorage.setItem('hashTagData',JSON.stringify(data)) ;
      state.hashTagData = data;
    }


}