<template>
    <div id="blog" class="banner">
        <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"
                />
            </div>
            <!---image---->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="banner-box-image"
                />

            </div>


            <div class="col-lg-8 col-md-8 col-sm-8 mx-auto mt-3">
                <div class="">
                    <h1 class="first-heading">{{articleDetail.title}}</h1>
                    <!--                    <h2 class="smallTitle mb-2">{{articleDetail.sub_title}}</h2>-->
                </div>
            </div>
        </div>

        <div class="row">
            <!---Footer--->
            <div class=" col-lg-8 col-sm-8 mx-auto">
                <div class="social-icon social-icon-blog d-flex mt-lg-4 mt-sm-0">
                    <div class="viewBanner mb-0 w-26 ml-lg-0  ml-md-4">{{articleDetail.total_views_skim}} Views</div>

<!--                    <div class="badge-detail">-->
<!--                        <table>-->
<!--                            <tbody class="table-body">-->
<!--                            <tr class="p-0">-->
<!--                                <td>-->
<!--                                    <label class="mr-1 mb-0 small-head supportive ml-5">-->
<!--                                <span class="badge" style="background-color: #28a745; color: #fff">-->
<!--                                Supportive-->
<!--                                </span>-->
<!--                                    </label>-->
<!--                                </td>-->

<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!--        <span class="badge" style="color: #fff; background-color: #343a40">Neutral-->
<!--                      </span>-->
<!--                                    </label>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <label class="pr-1 mb-0 small-head">-->
<!-- <span class="badge" style="color: #fff; background-color: #dc3545">-->
<!--                        Critique-->
<!--                      </span></label>-->
<!--                                </td>-->
<!--                            </tr>-->

<!--                            <tr>-->
<!--                                <td class="count"><label class="font-sd ml-5">{{articleDetail.total_supportive}}</label>-->
<!--                                </td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_neutral}}</label>-->
<!--                                </td>-->
<!--                                <td class="count"><label class="font-sd pr-1">{{articleDetail.total_critique}}</label>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->

                    <div class="progress w-50">
                        <div class="progress-bar bg-success" role="progressbar" :style="{'width':articleDetail.total_supportive_percentage+'%'}">
                            {{articleDetail.total_supportive_percentage}}%
                        </div>
                        <div class="progress-bar bg-dark" role="progressbar" :style="{'width':articleDetail.total_neutral_percentage+'%'}">
                            {{articleDetail.total_neutral_percentage}}%
                        </div>
                        <div class="progress-bar bg-danger" role="progressbar" :style="{'width':articleDetail.total_critique_percentage+'%'}">
                            {{articleDetail.total_critique_percentage}}%
                        </div>
                    </div>

                    <!-- <div class="socail-link w-25">
                        <table class="blog-info info-wrapper mb-2 w-100">
                            <tbody>
                            <tr>
                                <td class="w-73 pl-2 pr-2">
                                    <a href="">
                                        <i class="fas fa-share iconShare  d-sm-block d-md-none mr-3"></i>
                                       <label class="mb-0 mr-md-2 shareText articleText" style="color: gray; font-size: 16px;"> Share: </label>
                                    </a>
                                    <a class="social-icon socialIcon">
                                        <i class="fa-brands fa-facebook-f" style="
                          color: gray;
                          width: 17px;
                          font-size: 15px;
                          text-align: center;
                        "
                                        >
                                        </i>
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=" target="_blank" class="social-icon socialIcon">
                                        <i
                                                class="fa-brands fa-twitter"
                                                style="
                          color: gray;
                          width: 20px;
                          font-size: 15px;
                          text-align: center;
                        "
                                        >
                                        </i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import {mapGetters} from "vuex";
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";


    export default {
        name: "Banner",
        components: {userProfileDetail, VideoOrImageFrame},
        props: {
            articleDetail: Object,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>

<style scoped>

</style>
