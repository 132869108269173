<template>
    <div class="userComments">
        <div class="commentMain">
            <div class="innerSection" v-on:click="checkToggle">
                <span>Comments</span>
                <i class="fas fa-angle-double-down" v-if="isToggle"></i>
                <i class="fas fa-angle-double-up" v-if="!isToggle"></i>
            </div>
            <div class="commentBox" v-if="isToggle">
                <div class="col-xl-5 col-sm-6  m-auto mt-1" v-if="!isLogin">
                    <button class="btn  commentUserButton shadow-none"
                            data-toggle="modal" data-target="#LoginModal">
                        <span class="sign-in-text-color  text-muted">Sign in to post a comment </span>
                    </button>
                </div>
                <div class="row mt-3" v-if="isLogin">
                    <div class="col-md-1 col-sm-2 col-2 pr-0">
                        <div class="userImage">
                            <img
                                    class="rounded-circle"
                                    :src="getAuthUser.profile_pic"
                                    width="40"
                                    height="40"
                            />
                        </div>
                    </div>
                    <div class="col-md-11 col-sm-10 col-10 pl-0">
                        <form @submit.prevent="validateBeforeSubmit">
                            <div class="responseInput form-group">
                                <input
                                        v-validate="'required'"
                                        name="comment"
                                        v-model="comment"
                                        type="text"
                                        placeholder="Add Response"
                                        class="form-control first"/>

                                <div data-toggle="modal" data-target=".small-comment-section" class="second-input">
                                    <input
                                            v-validate="'required'"
                                            name="comment"
                                            v-model="comment"
                                            type="text"
                                            placeholder="Add Response"
                                            class="form-control"/>
                                </div>
                            </div>
                            <div class="class1 d-flex" id="class1">

                                <div class="readioBtn">
                                    <RadioButton :customIds="customIds" :isModal="false" :commentTag="commentTag" @emitCommentTag="emitCommentTag"/>
                                </div>
                                <div class="selectOption">


                                    <select v-model="type" class="form-control form-control-sm">
                                        <option value="1">General</option>

                                    </select>
                                </div>
                                <div class="commentBtn">
                                    <button :disabled="isValidateButton" type="submit"
                                            class=" btn-outline-info btn-sm commentBtn">Comment
                                    </button>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="userReply">
                            <div v-for="comment in articleDetailComments" :key="comment.id+'_'+Math.random()">
                                <userSendComment :comment="comment"/>
                            </div>
                        </div>
                    </div>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getArticleDetailComments"
                ></infinite-loading>

            </div>
        </div>

        <userCommentModal :getAuthUser="getAuthUser" :articleDetail="articleDetail" />
    </div>
</template>

<script>
    import userSendComment from "./UserSendComment.vue";
    import RadioButton from "../RadioButton/RadioButton";
    import InfiniteLoading from "vue-infinite-loading";
    import userCommentModal from "../Modals/userCommentModal";
    import {mapGetters} from "vuex";

    export default {
        name: "UserComments",
        components: {userSendComment, RadioButton, InfiniteLoading, userCommentModal},
        props: {
            articleId: Number
        },
        data() {
            return {
                isToggle: false,
                isCheck: false,
                comment: "",
                commentTag: "",
                type: "",
                customIds: ['supportive', 'neutral', 'critique']

            };
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                articleDetail: 'article/getArticleDetails',
                articleDetailCommentsPageNumber: 'article/getArticleDetailCommentsPageNumber',
                articleDetailComments: 'article/getArticleDetailComments'
            }),
            isValidateButton() {
                return this.type === '' && this.commentTag === "" && this.comment === ""
            }
        },
        methods: {
            emptyForm() {
                this.type = ''
                this.commentTag = ""
                this.comment = ""
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            getArticleSlug() {
                return localStorage.getItem('articleSlug')
            },
            emitCommentTag(val) {
                console.log("new",val);
                this.commentTag = val
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let payload = {
                            comment_tag: this.commentTag,
                            comment: this.comment,
                            type: this.type,
                            is_reply: false,
                            article_id: this.articleDetail.id,
                        }
                        this.$store.dispatch('article/addComment', payload).then(this.emptyForm());
                    }
                });
            },
            getArticleDetailComments($state) {
                let payload = {
                    page: this.articleDetailCommentsPageNumber,
                    articleId: this.articleId,
                    $state: $state,
                };
                this.$store.dispatch("article/getArticleDetailComments", payload);
            }
        },
        mounted() {
            this.$store.dispatch('article/setArticleDetailCommentsPageNumber',1);
        },
    };
</script>

<style>
    .commentMain {
        width: 720px;
        position: fixed;
        bottom: 0;
        right: 30px;
        z-index: 1030 !important;
    }

    .innerSection {
        border-radius: 10px 10px 0 0;
        display: flex;
        width: 300px;
        font-weight: bold;
        margin-left: auto;
        justify-content: space-between;
        align-items: center;
        color: #000;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        background-color: #f6f6f6;
    }

    .commentBox {
        background-color: #f6f6f6;
        border-radius: 10px 0 0 0;
        max-height: 100px;
        padding: 10px 20px;
        overflow-y: auto;
    }


    .commentBtn .commentBtn {
        height: 33px;
        align-items: center;
        margin-left: 0px !important;
    }

    .labelText {
        font-size: 11px;
    }

    .commentUserButton {
        border: 1px solid #4060a5;
        text-align: center;
        font-size: 17px;
        font-weight: 400;

    }

    .second-input {
        display: none;
    }
</style>
