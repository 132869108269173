<template>
    <div id="heading_request">

        <div class="row mt-5" id="row">

            <div class="col-lg-8 col-md-8 col-sm-8"></div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="btn-box">
                    <button class="btn float-right  mb-2 ml-2 acceptHeadingBtn">
                        Accept Heading Requests
                    </button>
                </div>
            </div>


            <div class="col-lg-12  col-md-12 col-sm-12 mt-5 text-center pr-3 pl-4 ">
                <h2 class="text">In order to access monetization features,
                    please contact us at support@theworded.com. You must have a valid PayPal account in order to be
                    eligible.</h2>
                <h2 class="text">To learn more about Heading Requests and other monetization
                    features, please visit our 
                    <a href="https://www.theworded.com/article/ae3A5UvHDEC" class="link">Demo Page</a>
                </h2>
            </div>



        </div>
    </div>
</template>

<script>


    export default {
        name: 'headingRequest',
        components:{
            
        }
    }
</script>

<style scoped>
    .text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 28px;
          font-family: "Oswald";
       font-weight: 400;
        margin-top: 0;
        line-height:1.5;
        text-align: left;
    }
    .text .link{
        color: #007bff!important;
        font-size: 28px;
          font-family: "Oswald";
       font-weight: 400;
       text-decoration: none;
    }

    .acceptHeadingBtn{
        background: none;
        border: 2px solid;
        border-bottom-width: 4px;
        font: inherit;
        height: 40px;
        letter-spacing: inherit;
        padding: 0 30px;
        border-radius: 0;
        text-transform: inherit;
        -webkit-transition: color 1s;
        transition: color 1s;
        text-align: center;
        font-weight: 400;
    }

    .acceptHeadingBtn:hover {
        color: #006692;
        -webkit-animation: zigzag 1s linear infinite;
        animation: zigzag 1s linear infinite;
        background: linear-gradient(135deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(225deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(315deg, #dee2e67d 0.25em, transparent 0.25em) 0 0,
        linear-gradient(45deg, #dee2e67d 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.75em;
        -webkit-transition: all ease .2s;
        transition: all ease .2s;
        cursor: pointer;
    }


    @media (max-width: 586px) {
    }

    @media (max-width: 767px) and (min-width: 587px) {
        #row {
            max-width: 1140px;
        }

    }

    @media (max-width: 991px) and (min-width: 768px) {
        .text {
            font-size: 15px;
            line-height: 1.5;
        }
    }

    @media (max-width: 1199px) and (min-width: 992px) {
        .text {
            font-size: 21px;
        }
    }


</style>
