<template>
<div id="blog">
    <div :class="mainClass">
        <iframe height="100%" v-if="videoUrl" :class="imageClass" frameborder="0" :width="width ? width : '100%'"
                :src="updateYoutube(videoUrl)"></iframe>
        <img v-else :class="imageClass" :src="imageUrl" :width="width ? width : '100%'" :alt="imageUrl"/>
    </div>
</div>

</template>

<script>
    export default {
        props: {
            videoUrl: String,
            imageUrl: String,
            width: String,
            height: String,
            imageClass: String,
            mainClass: String
        },
        data() {
            return {};
        },
        methods: {
            updateYoutube(youtube) {
                let youtubeVideoId = this.getYoutubeVideoId(youtube);
                if (youtubeVideoId === 'error') {
                    return;
                }
                return 'https://www.youtube.com/embed/' + youtubeVideoId;
            },
            getYoutubeVideoId(youtube) {
                var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                if (youtube.match(p)) {
                    return youtube.match(p)[1];
                }
                return false
            },
        }
    }
</script>

<style scoped>

</style>