export default {
    GET_CATEGORIES(state, response) {
        state.categories = [];
        let responseArray = response.data.data;
        responseArray.unshift(state.homeCategory);
        var size = 5;
        for (let i = 0; i < responseArray.length; i += size) {
            state.categories.push(responseArray.slice(i, i + size));
        }
        let index2 = state.categories.findIndex(x => x.find(c => c.id === state.activeCategory.id) !== undefined);
        if (index2 !== -1) {
            state.settings.initialSlide = index2
        }
        console.log("index2",index2)

    },
    CUSTOM_PARENT_CATEGORIES(state, response) {
        state.customCategories = [];
        response.data.data.map(x => {
            state.customCategories.push({name: x.category_name, code: x.id})
        })
    },
    SEARCH_CUSTOM_PARENT_CATEGORIES(state, search) {
        let filtered = state.customCategories.filter(x => x.name.toUpperCase().match(search.toUpperCase()));
        state.customCategories = []
        filtered.map(x => {
            state.customCategories.push({name: x.name, code: x.code})
        })
    },

    ADD_FAVOURITE_CATEGORY(state, response) {
        let index = state.customCategories.findIndex(x => x.code === response.data.data.category.id);
        if (index !== -1) {
            state.customCategories.splice(index, 1)
            state.categories[0].push({
                category_name: response.data.data.category.category_name,
                id: response.data.data.category.id,
                subscribers: response.data.data.subscribers,
                country_id: response.data.data.category.country_id,
                user_id: response.data.data.user.id,
                level: 0
            })
        }
        let responseArray = []
        state.categories.map(x=>{
            responseArray.push(...x);
        })
        var size = 5;
        state.categories = [];
        for (let i = 0; i < responseArray.length; i += size) {
            state.categories.push(responseArray.slice(i, i + size));
        }

        let index2 = state.categories.findIndex(x => x.find(c => c.id === state.activeCategory.id) !== undefined);
        if(index2 !== -1){
            state.settings.initialSlide = index2
        }
        console.log("index2",index2)
    },
    DELETE_FAVOURITE_CATEGORY(state, payload) {
        let arrayOfIndex = {i: -1, index: -1}
        for (let i = 0; i < state.categories.length; i++) {
            let index = state.categories[i].findIndex(x => x.id === payload.category_id);
            if (index > -1) {
                arrayOfIndex.i = i
                arrayOfIndex.index = index;
                break;
            }
        }
        if (arrayOfIndex.index !== -1 && arrayOfIndex.i !==-1) {
            state.categories[arrayOfIndex.i].splice(arrayOfIndex.index, 1)
            state.customCategories.push({
                code: payload.category_id,
                name: payload.category_name
            })
        }
        let responseArray = []
        state.categories.map(x=>{
            responseArray.push(...x);
        })
        var size = 5;
        state.categories = [];
        for (let i = 0; i < responseArray.length; i += size) {
            state.categories.push(responseArray.slice(i, i + size));
        }

        let index2 = state.categories.findIndex(x => x.find(c => c.id === state.activeCategory.id) !== undefined);
        if(index2 !== -1){
            state.settings.initialSlide = index2
        }
        console.log("index2",index2)

    },
    GET_WORLD_CATEGORIES(state, response) {
        state.worldCategories = [];
        response.data.data.map(x => {
            state.worldCategories.push({name: x.category_name, code: x.id})
        })
    },
    GET_WORLD_HASH_TAG(state, response) {
        state.worldHashTags = [];
        response.data.data.map(x => {
            state.worldHashTags.push({name: x.hash_tag_name, code: x.id})
        })
    },

    GET_COUNTRY_CATEGORIES(state, response) {
        state.countryCategories = [];
        response.data.data.map(x => {
            if (x.country_id !== 1) {
                state.countryCategories.push({name: x.category_name, code: x.id})
            }
        })
    },
    GET_COUNTRY_HASH_TAG(state, response) {
        state.countryHashTags = [];
        response.data.data.map(x => {
            if (x.country_id !== 1) {
                state.countryHashTags.push({name: x.hash_tag_name, code: x.id})
            }
        })
    },
    EMPTY_CATEGORIES(state) {
        state.categories = [];
    },
    UPDATE_CUSTOM_SELECTED_CATEGORIES(state) {
        let response = JSON.parse(localStorage.getItem('setCategory'));
        state.customSelectedCategory.id = response.id;
        state.customSelectedCategory.category_name = response.category_name;
    },
    UPDATE_TYPE(state,type){
        if(type !== 'undefined'){
            localStorage.setItem('type',JSON.stringify(type))
            state.type = type
        }
    },
    UPDATE_ACTIVE_CATEGORY(state, category) {
        localStorage.setItem('activeCategory', JSON.stringify(category))
        state.activeCategory = category

    },
    GET_TRENDING_HASHTAGS(state, response) {
        state.trendingHashTags = [];
        let responseArray = response.data.data;
        var size = 10;
        for (let i = 0; i < responseArray.length; i += size) {
            state.trendingHashTags.push(responseArray.slice(i, i + size));
        }

    },


}