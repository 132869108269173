<template>
    <div class="main_modal">
        <div class="customize_modal">
            <!-- Modal -->
            <div class="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModalLong" tabindex="-1"
                 role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg customize-modal" role="document">
                    <div class="modal-content" style="max-width:750px">

                        <!---header ----->
                        <div class="modal-header">
                            <h4 class="modal-title top-heading d-inline-block">Customization</h4>
                            <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!---modal-body---->
                        <div class="modal-body customize-modal-body">
                            <h6 class="modal_heading">Article Header Image</h6>
                            <div class="d-flex  model_card">

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="layout" type="radio" value="default" id="default" class=""
                                               checked>
                                        <label for="default" class="ml-2 radio-text">Default</label>
                                    </div>
                                    <img src="../../assets/images/Default.png" for="default">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="layout" type="radio" value="hide" id="hide">
                                        <label for="hide" class="ml-2 radio-text">Hide</label>
                                    </div>
                                    <img src="../../assets/images/Hide.png">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="layout" type="radio" value="article_width" id="article_width">
                                        <label for="article_width" class="ml-2 radio-text">Width</label>
                                    </div>
                                    <img src="../../assets/images/Article_Width.png">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="layout" type="radio" value="banner" id="banner">
                                        <label for="banner" class="ml-2 radio-text">Banner</label>
                                    </div>
                                    <img src="../../assets/images/Banner.png">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="layout" type="radio" value="oversized" id="oversize">
                                        <label for="oversize" class="ml-2 radio-text">Oversize</label>
                                    </div>
                                    <img src="../../assets/images/Oversized.png">
                                </div>
                            </div>

                            <div class="subheading">
                                <h6 class="modal_heading mt-lg-4 mt-md-4  mt-sm-3">Subtitle</h6>
                                <div class="d-flex  model_card">
                                    <div class="radio_button">
                                        <input v-model="customizeTitle" type="radio" value="default"
                                               id="subtitleDefault" checked>
                                        <label for="subtitleDefault" class="ml-2 radio-text">Default</label>
                                    </div>

                                    <div class="radio_button">
                                        <input v-model="customizeTitle" type="radio" value="hide" id="subtitleHide">
                                        <label for="subtitleHide" class="ml-2 radio-text">Hide</label>
                                    </div>
                                </div>
                            </div>

                            <div class=" bottom-heading">
                                <label class="modal_heading mt-lg-4 mt-md-4  mt-sm-3">Desktop Thumbnail Image</label>
                                <img @click="openModal" data-toggle="modal" data-target="#customizationHeaderImageModal" data-dismiss="modal" aria-label="Close"
                                    href="javascript:void(0)"  :src="headerImage(image)" width="50" height="40" class="cursor ml-3" />
                                <label class="modal_heading mt-lg-4 mt-md-4 ml-3 mt-sm-3">Mobile Thumbnail Image</label>
                                <img @click="openMobileModal" data-toggle="modal" data-target="#customizationMobileThumbnailImageModal" data-dismiss="modal" aria-label="Close"
                                     href="javascript:void(0)"  :src="headerMobileImage(mobileImage)" width="50" height="40" class="cursor ml-3" />
                                <p class="para mt-3 mb-0">This image will replace the Header Image on Article Page. Use this
                                    option if using two different size images for the Card Thumbnail and the Article
                                    Header.</p>
                            </div>
                        </div>

                        <!-----footer----->

                        <div class="modal-footer">
                            <button @click="updateCustomization" type="button" data-dismiss="modal" aria-label="Close"
                                    class="text-dark" id="button">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CustomizationHeaderImageModal
                @emitCustomizationHeaderImagePreview="emitCustomizationHeaderImagePreview"
                @emitCustomizationHeaderImage="emitCustomizationHeaderImage"
        />
        <CustomizationMobileThumbnailImageModal
                @emitCustomizationMobileImagePreview="emitCustomizationMobileImagePreview"
                @emitCustomizationMobileThumbnailImage="emitCustomizationMobileThumbnailImage"
        />
    </div>
</template>

<script>
    import CustomizationHeaderImageModal from "./CustomizationHeaderImageModal";
    import CustomizationMobileThumbnailImageModal from "./CustomizationMobileThumbnailImageModal";

    export default {
        name: 'customizationModal',
        components:{
            CustomizationHeaderImageModal,
            CustomizationMobileThumbnailImageModal
        },
        data() {
            return {
                layout: "default",
                customizeTitle: "default",
                isCustomization : false,
                image:'',
                mobileImage:''
            }
        },
        methods: {
            updateCustomization() {
                this.$emit('emitCustomization', {layout: this.layout, customizeTitle: this.customizeTitle})
            },
            openModal(){
                this.isCustomization = true
            },
            openMobileModal(){
                this.isCustomization = true
            },
            emitCustomizationMobileImagePreview(file){
                this.mobileImage = file
            },
            emitCustomizationHeaderImagePreview(file){
                this.image = file
            },
            emitCustomizationHeaderImage(file){
                this.$emit('emitCustomizationHeaderImage',file)
            },
            emitCustomizationMobileThumbnailImage(file){
                this.$emit('emitCustomizationMobileThumbnailImage',file)
            },
            headerImage(image) {
                return image.length !== 0 ? image : '/no-image-log.png';
            },
            headerMobileImage(image) {
                return image.length !== 0 ? image : '/no-image-log.png';
            },
        },
        watch: {
            layout() {
                this.updateCustomization()
            },
            customizeTitle() {
                this.updateCustomization()
            }
        }
    }
</script>



   